@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=noto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

/*----------------------------------------------------------------------
    Template Name: Jeena - IT solutions & Services HTML Template
    Template URI: https://webtend.net/demo/html/jeena/
    Author: WebTend
    Author URI:  https://webtend.net/
    Version: 1.0

    Note: This is Main Style CSS File. */
/*----------------------------------------------------------------------
	CSS INDEX
	----------------------

    ## Default Style
    ## Common Classes
    ## Repeat Style
    ## Padding Margin
    ## Custom Animation
    ## Header style
    ## Hero Area
    ## Page Banner
    ## Main Slider
    ## About Area
    ## Services Area
    ## Features Style
    ## Partners Area
    ## Projects Area
    ## Work Process
    ## Team Members
    ## Counter Style
    ## Pricing Plan
    ## Testimonials
    ## Blog Area
    ## Contact Forms
    ## FAQs Area
    ## Shop
    ## Cart Checkout
    ## Videos Area
    ## Sidebar Widgets
    ## Main Footer */
/* -------------------------------------------------------------- */
/*******************************************************/
/******************* ## Default Style ******************/
/*******************************************************/
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body {
  color: var(--base-color);
  background: white;
  font-weight: 400;
  line-height: 32px;
  font-size: 16px;
  font-family: "Noto", sans-serif !important;
  --base-color: #838694;
  /* --heading-color: #1b1f2e; */
  --heading-color: #0A2836;
  --primary-color: #1654AF;
  --secondary-color: #1654AF;
  --lighter-color: #f7f7f9;
  --border-color: rgba(21, 16, 31, 0.1);
}

a {
  color: var(--base-color);
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

a:hover {
  color: var(--primary-color);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: var(--heading-color);
}

.text-white h1,
.text-white h1 a,
.text-white h2,
.text-white h2 a,
.text-white h3,
.text-white h3 a,
.text-white h4,
.text-white h4 a,
.text-white h5,
.text-white h5 a,
.text-white h6,
.text-white h6 a,
.text-white .h1,
.text-white .h1 a,
.text-white .h2,
.text-white .h2 a,
.text-white .h3,
.text-white .h3 a,
.text-white .h4,
.text-white .h4 a,
.text-white .h5,
.text-white .h5 a,
.text-white .h6,
.text-white .h6 a {
  color: white;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

mark {
  color: var(--secondary-color);
  background: transparent;
  text-decoration: underline;
}

header:after,
section:after,
footer:after {
  display: block;
  clear: both;
  content: "";
}

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 15px 30px;
  background-color: #fff;
  border: 1px solid var(--border-color);
}

@keyframes example {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-page,
.about-page,
.outsourcing-page,
.product-page,
.projects-page,
.careers-page,
.contact-page {
  opacity: 1;
  animation: example;
  animation-duration: 2s;
}

.nice-select {
  font-size: 18px;
  line-height: 1.3;
}

.nice-select:after {
  height: 8px;
  width: 8px;
  right: 25px;
  border-color: var(--heading-color);
}

.nice-select .current {
  font-weight: 500;
  color: var(--heading-color);
}

.nice-select .list {
  width: 100%;
  border-radius: 0;
}

textarea {
  display: inherit;
  padding-top: 20px;
}

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--base-color);
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

input:focus,
button:focus,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #cfdbf1;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=checkbox],
input[type=radio] {
  height: auto;
  width: auto;
}

/*******************************************************/
/******************  ## Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1320px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .container.container-1440 {
    max-width: 1470px;
  }
}

@media only screen and (min-width: 576px) {
  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 1400px) {
  .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media only screen and (max-width: 375px) {
  .col-small {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.row {
  --bs-gutter-x: 30px;
}

.no-gap {
  --bs-gutter-x: 0;
}

@media only screen and (min-width: 1400px) {
  .gap-10 {
    --bs-gutter-x: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-20 {
    --bs-gutter-x: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-30 {
    --bs-gutter-x: 30px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-40 {
    --bs-gutter-x: 40px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-50 {
    --bs-gutter-x: 50px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-60 {
    --bs-gutter-x: 60px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-70 {
    --bs-gutter-x: 70px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-80 {
    --bs-gutter-x: 80px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-90 {
    --bs-gutter-x: 90px;
  }
}

@media only screen and (min-width: 1400px) {
  .gap-100 {
    --bs-gutter-x: 100px;
  }
}

@media only screen and (min-width: 1200px) {
  .row-cols-xl-7>* {
    width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

/** Section Title style **/
.section-title {
  margin-top: -7px;
  position: relative;
}

.section-title .sub-title {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  color: var(--secondary-color);
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 30px !important;
    ;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 479px) {
  .section-title h3 {
    font-size: 25px;
  }
}

.section-title .bg-text {
  font-size: 16vw;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.05;
  top: 65%;
}

.section-title.text-center .bg-text {
  color: white;
}

/** Button style **/
.theme-btn,
a.theme-btn {
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 700;
  text-align: center;
  padding: 12px 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--heading-color);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  background: var(--secondary-color);
}

.theme-btn i,
a.theme-btn i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 10px;
}

.theme-btn:after,
a.theme-btn:after {
  position: absolute;
  background: var(--secondary-color);
  transition: 0.3;
}

.theme-btn:hover,
a.theme-btn:hover {
  background: #1971D1;
}

.theme-btn:hover:after,
a.theme-btn:hover:after {
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.theme-btn:hover i,
a.theme-btn:hover i {
  margin-left: 13px;
  margin-right: -3px;
}

.theme-btn.style-two,
a.theme-btn.style-two {
  color: white;
  background: var(--secondary-color);
  border-radius: 7px;
}

.theme-btn.style-two:after,
a.theme-btn.style-two:after {
  background: var(--secondary-color);
}

.theme-btn.style-two:hover,
a.theme-btn.style-two:hover {
  background: #1971D1;
}

.theme-btn.style-three,
a.theme-btn.style-three {
  color: white;
  background: var(--secondary-color);
}

.theme-btn.style-three:after,
a.theme-btn.style-three:after {
  background: var(--heading-color);
}

.theme-btn.style-four,
a.theme-btn.style-four {
  color: var(--heading-color);
  background: transparent;
  border: 1px solid var(--border-color);
}

.theme-btn.style-four:not(:hover):after,
a.theme-btn.style-four:not(:hover):after {
  background: transparent;
}

@media only screen and (max-width: 575px) {

  .theme-btn,
  a.theme-btn {
    padding: 9px 25px;
  }
}

/* Details Btn */
.details-btn {
  font-size: 22px;
  width: 70px;
  height: 70px;
  background: white;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--primary-color);
}

@media only screen and (max-width: 479px) {
  .details-btn {
    width: 45px;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
  }
}

/* Read More */
.read-more {
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: underline;
  text-transform: capitalize;
}

.read-more i {
  float: right;
  font-weight: 400;
  margin-left: 7px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.read-more:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

.read-more:hover i {
  margin-left: 10px;
}

/* List style One */
/* .list-style-one li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  color: var(--heading-color);
}

.list-style-one li:not(:last-child) {
  margin-bottom: 8px;
} */

.list-style-one i {
  color: #1654AF;
}

/* .list-style-one li:before {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 20px;
  height: 20px;
  font-size: 11px;
  margin-top: 6px;
  content: "\f00c";
  font-weight: 600;
  line-height: 16px;
  border: 2px solid;
  text-align: center;
  margin-right: 12px;
  border-radius: 50%;
  color: var(--secondary-color);
  font-family: 'Font Awesome 5 Pro';
} */

/* List style Two */
.list-style-two li:not(:last-child) {
  margin-bottom: 8px;
}

.list-style-two li a {
  position: relative;
}

.list-style-two li a:before {
  content: "\f101";
  position: absolute;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-family: "Font Awesome 5 Pro";
}

.list-style-two li a:hover {
  padding-left: 20px;
  text-decoration: underline;
}

.list-style-two li a:hover:before {
  opacity: 1;
}

/* List style Three */
.list-style-three li:not(:last-child) {
  margin-bottom: 4px;
}

/* .list-style-three li:before {
  content: "\f101";
  margin-right: 10px;
  font-family: 'Font Awesome 5 Pro';
} */

/** Social Link One **/
.social-style-one {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -10px;
  margin-right: -10px;
}

.social-style-one a {
  margin-left: 10px;
  margin-right: 10px;
}

/** Social Link Two **/
.social-style-two {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -5px;
  margin-right: -5px;
}

.social-style-two a {
  margin-left: 5px;
  margin-right: 5px;
  color: var(--secondary-color);
  width: 40px;
  height: 40px;
  background: var(--lighter-color);
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.social-style-two a:hover {
  color: white;
  background: var(--secondary-color);
}

/** Social Link Three **/
.social-style-three {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.social-style-three a {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.social-style-three a:hover {
  color: var(--secondary-color);
}

/** Tab Style One **/
.tab-style-one {
  border-bottom: 1px solid var(--border-color);
}

.tab-style-one .nav-link {
  font-size: 18px;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: -1px;
  background: transparent;
  border-bottom: 2px solid transparent;
}

@media only screen and (min-width: 1200px) {
  .tab-style-one .nav-link {
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .tab-style-one .nav-link {
    font-size: 16px;
  }
}

.tab-style-one .nav-link.active {
  color: var(--secondary-color);
  border-bottom-color: var(--secondary-color);
}

.tab-style-one .nav-item:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .tab-style-one .nav-item:not(:last-child) {
    margin-right: 20px;
  }
}

/*** Preloader style ** */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center center;
}

.preloader .custom-loader {
  width: 55px;
  aspect-ratio: 1;
  --c: conic-gradient(from -90deg, #30f0b6 90deg, #0000 0);
  background: var(--c), var(--c);
  background-size: 40% 40%;
  -webkit-animation: preloader 1s infinite alternate;
  animation: preloader 1s infinite alternate;
}

@-webkit-keyframes preloader {

  0%,
  10% {
    background-position: 0 0, 0 calc(100%/3);
  }

  50% {
    background-position: 0 0, calc(100%/3) calc(100%/3);
  }

  90%,
  100% {
    background-position: 0 0, calc(100%/3) 0;
  }
}

@keyframes preloader {

  0%,
  10% {
    background-position: 0 0, 0 calc(100%/3);
  }

  50% {
    background-position: 0 0, calc(100%/3) calc(100%/3);
  }

  90%,
  100% {
    background-position: 0 0, calc(100%/3) 0;
  }
}

/* Pagination */
.pagination {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}

.pagination li {
  margin: 10px 5px 0;
}

.pagination li a,
.pagination li .page-link {
  padding: 0;
  width: 45px;
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  color: var(--heading-color);
  border: 2px solid var(--border-color);
}

.pagination li.disabled .page-link,
.pagination li:last-child .page-link {
  border-radius: 50%;
}

.pagination li.active .page-link,
.pagination li:hover:not(.disabled) .page-link {
  color: white;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Rating */
.ratting {
  line-height: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ratting i {
  margin: 3px;
  color: #f1b000;
  font-size: 14px;
}

/*** Slick Dots ***/
.slick-arrow {
  width: 40px;
  height: 40px;
  font-size: 22px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #FEFEFF;
  border-radius: 50%;
  color: var(--heading-color);
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

.slick-arrow:focus,
.slick-arrow:hover {
  /* background: var(--secondary-color);
  color: #fff; */
}

.slider-arrow .slick-arrow {
  border: 1px solid var(--border-color);
}

.slider-arrow .slick-arrow:not(:last-child) {
  margin-right: 5px;
}

.slider-arrow .slick-arrow:focus,
.slider-arrow .slick-arrow:hover {
  /* color: white;
  background: var(--secondary-color);
  border-color: var(--secondary-color); */
}

/*** Slick Dots ***/
.slick-dots {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slick-dots li {
  position: relative;
  cursor: pointer;
  width: 10px;
  height: 10px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin: 10px 5px 0;
  border-radius: 50%;
  background: transparent;
  border: 2px solid var(--secondary-color);
}

.slick-dots li button {
  opacity: 0;
}

.slick-dots li.slick-active {
  background: #fff
}

/*** Scroll Top style ***/
.scroll-top {
  position: fixed;
  bottom: 25px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 99;
  color: white;
  display: none;
  font-size: 14px;
  cursor: pointer;
  line-height: 40px;
  border-radius: 5px;
  background: var(--primary-color);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

/* Text White */
.text-white *,
.text-white a,
.text-white .sub-title,
.text-white .read-more,
.text-white .list-style-one li,
.text-white .list-style-one li:before,
.text-white .counter-text-wrap .count-text,
.text-white .hotline .content a:not(:hover) {
  color: white;
}

.text-white .service-two-item .icon i {
  color: var(--primary-color);
}

.text-white .service-two-item .content p {
  opacity: 0.6;
}

.text-white .hotline>i {
  background: white;
}

.text-white .hotline>i {
  border-color: white;
}

/* Wave Shapes */
.wave-shapes {
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100px;
  width: calc(100% + 100px);
  height: 100%;
}

.wave-shapes .shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-animation: leftRightOne 6s infinite;
  animation: leftRightOne 6s infinite;
}

.wave-shapes .shape.two {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

/*Project Filter*/
.filter-btns-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.filter-btns-one li {
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  padding: 4px 20px;
  border-radius: 5px;
  margin: 0 5px 10px;
  color: var(--heading-color);
}

@media only screen and (max-width: 375px) {
  .filter-btns-one li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.filter-btns-one li.current {
  background: white;
  color: var(--secondary-color);
  text-decoration: underline;
  -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5);
  box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5);
}

.filter-btns-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 500;
  margin-left: -18px;
  margin-right: -18px;
}

@media only screen and (min-width: 768px) {
  .filter-btns-two {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-btns-two {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.filter-btns-two li {
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin: 0 18px 10px;
  border-bottom: 3px solid transparent;
}

@media only screen and (max-width: 575px) {
  .filter-btns-two li {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.filter-btns-two li.current {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Position */
.rel {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.overlay {
  z-index: 1;
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: var(--heading-color);
}

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center;
  /* background-image: url('./images/hero-two.jpg'); */
}

/* Color + Background */
.bgc-black {
  background-color: #0f1425;
}

.bgc-gray {
  background-color: var(--heading-color);
}

.bgc-primary {
  background-color: var(--primary-color);
}

.bgc-secondary {
  background-color: var(--secondary-color);
}

.bgc-lighter {
  background-color: var(--lighter-color);
}

.color-secondary {
  color: var(--secondary-color);
}

.bgc-black-with-lighting {
  background: #1e1e22;
}

.bgc-black-with-lighting:after,
.bgc-black-with-lighting:before {
  position: absolute;
  width: 450px;
  height: 450px;
  z-index: -1;
  content: '';
  border-radius: 50%;
  background: var(--secondary-color);
  -webkit-animation: zoomInOut 5s infinite;
  animation: zoomInOut 5s infinite;
}

.bgc-black-with-lighting:after {
  right: -8%;
  top: -15%;
  -webkit-filter: blur(110px);
  filter: blur(110px);
}

.bgc-black-with-lighting:before {
  left: -10%;
  top: 20%;
  -webkit-filter: blur(350px);
  filter: blur(350px);
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px;
}

/*******************************************************/
/******************* ## Repeat Style ******************/
/*******************************************************/
.heading,
.section-title .bg-text,
.tab-style-one .nav-link,
h1,
.h1,
h2,
.h2,
.counter-text-wrap .count-text,
.pricing-plan-item .price,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.slider-area-two .slider-bg-text,
.why-choose-tab .nav .nav-link,
.about-four-content .nav li .nav-link,
.about-five-images .experience-years .years,
.shop-shorter .filter-part span {
  font-weight: 600 !important;
  color: var(--heading-color);
  font-family: "Poppins", sans-serif;
}

h1,
.h1 {
  line-height: 1.1;
  /* font-size: 85px; */
}

h2,
.h2,
.counter-text-wrap .count-text,
.pricing-plan-item .price {
  line-height: 1.25;
  font-size: 42px !important;
}

h3,
.h3 {
  line-height: 1.25;
  font-size: 32px;
}

h4,
.h4 {
  line-height: 1.35;
  font-size: 22px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

.testimonial-item .image img,
.blog-item .content {
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

/*******************************************************/
/************** ## Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-125 {
  padding: 125px;
}

.p-130 {
  padding: 130px;
}

.p-135 {
  padding: 135px;
}

.p-140 {
  padding: 140px;
}

.p-145 {
  padding: 145px;
}

.p-150 {
  padding: 150px;
}

.p-155 {
  padding: 155px;
}

.p-160 {
  padding: 160px;
}

.p-165 {
  padding: 165px;
}

.p-170 {
  padding: 170px;
}

.p-175 {
  padding: 175px;
}

.p-180 {
  padding: 180px;
}

.p-185 {
  padding: 185px;
}

.p-190 {
  padding: 190px;
}

.p-195 {
  padding: 195px;
}

.p-200 {
  padding: 200px;
}

.p-205 {
  padding: 205px;
}

.p-210 {
  padding: 210px;
}

.p-215 {
  padding: 215px;
}

.p-220 {
  padding: 220px;
}

.p-225 {
  padding: 225px;
}

.p-230 {
  padding: 230px;
}

.p-235 {
  padding: 235px;
}

.p-240 {
  padding: 240px;
}

.p-245 {
  padding: 245px;
}

.p-250 {
  padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px;
}

.pt-15,
.py-15 {
  padding-top: 15px;
}

.pt-20,
.py-20 {
  padding-top: 20px;
}

.pt-25,
.py-25 {
  padding-top: 25px;
}

.pt-30,
.py-30 {
  padding-top: 30px;
}

.pt-35,
.py-35 {
  padding-top: 35px;
}

.pt-40,
.py-40 {
  padding-top: 40px;
}

.pt-45,
.py-45 {
  padding-top: 45px;
}

.pt-50,
.py-50 {
  padding-top: 50px;
}

.pt-55,
.py-55 {
  padding-top: 55px;
}

.pt-60,
.py-60 {
  padding-top: 60px;
}

.pt-65,
.py-65 {
  padding-top: 65px;
}

.pt-70,
.py-70 {
  padding-top: 70px;
}

.pt-75,
.py-75 {
  padding-top: 75px;
}

.pt-80,
.py-80 {
  padding-top: 80px;
}

.pt-85,
.py-85 {
  padding-top: 85px;
}

.pt-90,
.py-90 {
  padding-top: 90px;
}

.pt-95,
.py-95 {
  padding-top: 95px;
}

.pt-100,
.py-100 {
  padding-top: 100px;
}

.pt-105,
.py-105 {
  padding-top: 105px;
}

.pt-110,
.py-110 {
  padding-top: 110px;
}

.pt-115,
.py-115 {
  padding-top: 115px;
}

.pt-120,
.py-120 {
  padding-top: 120px;
}

.pt-125,
.py-125 {
  padding-top: 125px;
}

.pt-130,
.py-130 {
  padding-top: 130px;
}

.pt-135,
.py-135 {
  padding-top: 135px;
}

.pt-140,
.py-140 {
  padding-top: 140px;
}

.pt-145,
.py-145 {
  padding-top: 145px;
}

.pt-150,
.py-150 {
  padding-top: 150px;
}

.pt-155,
.py-155 {
  padding-top: 155px;
}

.pt-160,
.py-160 {
  padding-top: 160px;
}

.pt-165,
.py-165 {
  padding-top: 165px;
}

.pt-170,
.py-170 {
  padding-top: 170px;
}

.pt-175,
.py-175 {
  padding-top: 175px;
}

.pt-180,
.py-180 {
  padding-top: 180px;
}

.pt-185,
.py-185 {
  padding-top: 185px;
}

.pt-190,
.py-190 {
  padding-top: 190px;
}

.pt-195,
.py-195 {
  padding-top: 195px;
}

.pt-200,
.py-200 {
  padding-top: 200px;
}

.pt-205,
.py-205 {
  padding-top: 205px;
}

.pt-210,
.py-210 {
  padding-top: 210px;
}

.pt-215,
.py-215 {
  padding-top: 215px;
}

.pt-220,
.py-220 {
  padding-top: 220px;
}

.pt-225,
.py-225 {
  padding-top: 225px;
}

.pt-230,
.py-230 {
  padding-top: 230px;
}

.pt-235,
.py-235 {
  padding-top: 235px;
}

.pt-240,
.py-240 {
  padding-top: 240px;
}

.pt-245,
.py-245 {
  padding-top: 245px;
}

.pt-250,
.py-250 {
  padding-top: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px;
}

.pb-15,
.py-15 {
  padding-bottom: 15px;
}

.pb-20,
.py-20 {
  padding-bottom: 20px;
}

.pb-25,
.py-25 {
  padding-bottom: 25px;
}

.pb-30,
.py-30 {
  padding-bottom: 30px;
}

.pb-35,
.py-35 {
  padding-bottom: 35px;
}

.pb-40,
.py-40 {
  padding-bottom: 40px;
}

.pb-45,
.py-45 {
  padding-bottom: 45px;
}

.pb-50,
.py-50 {
  padding-bottom: 50px;
}

.pb-55,
.py-55 {
  padding-bottom: 55px;
}

.pb-60,
.py-60 {
  padding-bottom: 60px;
}

.pb-65,
.py-65 {
  padding-bottom: 65px;
}

.pb-70,
.py-70 {
  padding-bottom: 70px;
}

.pb-75,
.py-75 {
  padding-bottom: 75px;
}

.pb-80,
.py-80 {
  padding-bottom: 80px;
}

.pb-85,
.py-85 {
  padding-bottom: 85px;
}

.pb-90,
.py-90 {
  padding-bottom: 90px;
}

.pb-95,
.py-95 {
  padding-bottom: 95px;
}

.pb-100,
.py-100 {
  padding-bottom: 100px;
}

.pb-105,
.py-105 {
  padding-bottom: 105px;
}

.pb-110,
.py-110 {
  padding-bottom: 110px;
}

.pb-115,
.py-115 {
  padding-bottom: 115px;
}

.pb-120,
.py-120 {
  padding-bottom: 120px;
}

.pb-125,
.py-125 {
  padding-bottom: 125px;
}

.pb-130,
.py-130 {
  padding-bottom: 130px;
}

.pb-135,
.py-135 {
  padding-bottom: 135px;
}

.pb-140,
.py-140 {
  padding-bottom: 140px;
}

.pb-145,
.py-145 {
  padding-bottom: 145px;
}

.pb-150,
.py-150 {
  padding-bottom: 150px;
}

.pb-155,
.py-155 {
  padding-bottom: 155px;
}

.pb-160,
.py-160 {
  padding-bottom: 160px;
}

.pb-165,
.py-165 {
  padding-bottom: 165px;
}

.pb-170,
.py-170 {
  padding-bottom: 170px;
}

.pb-175,
.py-175 {
  padding-bottom: 175px;
}

.pb-180,
.py-180 {
  padding-bottom: 180px;
}

.pb-185,
.py-185 {
  padding-bottom: 185px;
}

.pb-190,
.py-190 {
  padding-bottom: 190px;
}

.pb-195,
.py-195 {
  padding-bottom: 195px;
}

.pb-200,
.py-200 {
  padding-bottom: 200px;
}

.pb-205,
.py-205 {
  padding-bottom: 205px;
}

.pb-210,
.py-210 {
  padding-bottom: 210px;
}

.pb-215,
.py-215 {
  padding-bottom: 215px;
}

.pb-220,
.py-220 {
  padding-bottom: 220px;
}

.pb-225,
.py-225 {
  padding-bottom: 225px;
}

.pb-230,
.py-230 {
  padding-bottom: 230px;
}

.pb-235,
.py-235 {
  padding-bottom: 235px;
}

.pb-240,
.py-240 {
  padding-bottom: 240px;
}

.pb-245,
.py-245 {
  padding-bottom: 245px;
}

.pb-250,
.py-250 {
  padding-bottom: 250px;
}

/* Margin Around */
.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-125 {
  margin: 125px;
}

.m-130 {
  margin: 130px;
}

.m-135 {
  margin: 135px;
}

.m-140 {
  margin: 140px;
}

.m-145 {
  margin: 145px;
}

.m-150 {
  margin: 150px;
}

.m-155 {
  margin: 155px;
}

.m-160 {
  margin: 160px;
}

.m-165 {
  margin: 165px;
}

.m-170 {
  margin: 170px;
}

.m-175 {
  margin: 175px;
}

.m-180 {
  margin: 180px;
}

.m-185 {
  margin: 185px;
}

.m-190 {
  margin: 190px;
}

.m-195 {
  margin: 195px;
}

.m-200 {
  margin: 200px;
}

.m-205 {
  margin: 205px;
}

.m-210 {
  margin: 210px;
}

.m-215 {
  margin: 215px;
}

.m-220 {
  margin: 220px;
}

.m-225 {
  margin: 225px;
}

.m-230 {
  margin: 230px;
}

.m-235 {
  margin: 235px;
}

.m-240 {
  margin: 240px;
}

.m-245 {
  margin: 245px;
}

.m-250 {
  margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px;
}

.mt-15,
.my-15 {
  margin-top: 15px;
}

.mt-20,
.my-20 {
  margin-top: 20px;
}

.mt-25,
.my-25 {
  margin-top: 25px;
}

.mt-30,
.my-30 {
  margin-top: 30px;
}

.mt-35,
.my-35 {
  margin-top: 35px;
}

.mt-40,
.my-40 {
  margin-top: 40px;
}

.mt-45,
.my-45 {
  margin-top: 45px;
}

.mt-50,
.my-50 {
  margin-top: 50px;
}

.mt-55,
.my-55 {
  margin-top: 55px;
}

.mt-60,
.my-60 {
  margin-top: 60px;
}

.mt-65,
.my-65 {
  margin-top: 65px;
}

.mt-70,
.my-70 {
  margin-top: 70px;
}

.mt-75,
.my-75 {
  margin-top: 75px;
}

.mt-80,
.my-80 {
  margin-top: 80px;
}

.mt-85,
.my-85 {
  margin-top: 85px;
}

.mt-90,
.my-90 {
  margin-top: 90px;
}

.mt-95,
.my-95 {
  margin-top: 95px;
}

.mt-100,
.my-100 {
  margin-top: 100px;
}

.mt-105,
.my-105 {
  margin-top: 105px;
}

.mt-110,
.my-110 {
  margin-top: 110px;
}

.mt-115,
.my-115 {
  margin-top: 115px;
}

.mt-120,
.my-120 {
  margin-top: 120px;
}

.mt-125,
.my-125 {
  margin-top: 125px;
}

.mt-130,
.my-130 {
  margin-top: 130px;
}

.mt-135,
.my-135 {
  margin-top: 135px;
}

.mt-140,
.my-140 {
  margin-top: 140px;
}

.mt-145,
.my-145 {
  margin-top: 145px;
}

.mt-150,
.my-150 {
  margin-top: 150px;
}

.mt-155,
.my-155 {
  margin-top: 155px;
}

.mt-160,
.my-160 {
  margin-top: 160px;
}

.mt-165,
.my-165 {
  margin-top: 165px;
}

.mt-170,
.my-170 {
  margin-top: 170px;
}

.mt-175,
.my-175 {
  margin-top: 175px;
}

.mt-180,
.my-180 {
  margin-top: 180px;
}

.mt-185,
.my-185 {
  margin-top: 185px;
}

.mt-190,
.my-190 {
  margin-top: 190px;
}

.mt-195,
.my-195 {
  margin-top: 195px;
}

.mt-200,
.my-200 {
  margin-top: 200px;
}

.mt-205,
.my-205 {
  margin-top: 205px;
}

.mt-210,
.my-210 {
  margin-top: 210px;
}

.mt-215,
.my-215 {
  margin-top: 215px;
}

.mt-220,
.my-220 {
  margin-top: 220px;
}

.mt-225,
.my-225 {
  margin-top: 225px;
}

.mt-230,
.my-230 {
  margin-top: 230px;
}

.mt-235,
.my-235 {
  margin-top: 235px;
}

.mt-240,
.my-240 {
  margin-top: 240px;
}

.mt-245,
.my-245 {
  margin-top: 245px;
}

.mt-250,
.my-250 {
  margin-top: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px;
}

.mb-15,
.my-15 {
  margin-bottom: 15px;
}

.mb-20,
.my-20 {
  margin-bottom: 20px;
}

.mb-25,
.my-25 {
  margin-bottom: 25px;
}

.mb-30,
.my-30 {
  margin-bottom: 30px;
}

.mb-35,
.my-35 {
  margin-bottom: 35px;
}

.mb-40,
.my-40 {
  margin-bottom: 40px;
}

.mb-45,
.my-45 {
  margin-bottom: 45px;
}

.mb-50,
.my-50 {
  margin-bottom: 50px;
}

.mb-55,
.my-55 {
  margin-bottom: 55px;
}

.mb-60,
.my-60 {
  margin-bottom: 60px;
}

.mb-65,
.my-65 {
  margin-bottom: 65px;
}

.mb-70,
.my-70 {
  margin-bottom: 70px;
}

.mb-75,
.my-75 {
  margin-bottom: 75px;
}

.mb-80,
.my-80 {
  margin-bottom: 80px;
}

.mb-85,
.my-85 {
  margin-bottom: 85px;
}

.mb-90,
.my-90 {
  margin-bottom: 90px;
}

.mb-95,
.my-95 {
  margin-bottom: 95px;
}

.mb-100,
.my-100 {
  margin-bottom: 100px;
}

.mb-105,
.my-105 {
  margin-bottom: 105px;
}

.mb-110,
.my-110 {
  margin-bottom: 110px;
}

.mb-115,
.my-115 {
  margin-bottom: 115px;
}

.mb-120,
.my-120 {
  margin-bottom: 120px;
}

.mb-125,
.my-125 {
  margin-bottom: 125px;
}

.mb-130,
.my-130 {
  margin-bottom: 130px;
}

.mb-135,
.my-135 {
  margin-bottom: 135px;
}

.mb-140,
.my-140 {
  margin-bottom: 140px;
}

.mb-145,
.my-145 {
  margin-bottom: 145px;
}

.mb-150,
.my-150 {
  margin-bottom: 150px;
}

.mb-155,
.my-155 {
  margin-bottom: 155px;
}

.mb-160,
.my-160 {
  margin-bottom: 160px;
}

.mb-165,
.my-165 {
  margin-bottom: 165px;
}

.mb-170,
.my-170 {
  margin-bottom: 170px;
}

.mb-175,
.my-175 {
  margin-bottom: 175px;
}

.mb-180,
.my-180 {
  margin-bottom: 180px;
}

.mb-185,
.my-185 {
  margin-bottom: 185px;
}

.mb-190,
.my-190 {
  margin-bottom: 190px;
}

.mb-195,
.my-195 {
  margin-bottom: 195px;
}

.mb-200,
.my-200 {
  margin-bottom: 200px;
}

.mb-205,
.my-205 {
  margin-bottom: 205px;
}

.mb-210,
.my-210 {
  margin-bottom: 210px;
}

.mb-215,
.my-215 {
  margin-bottom: 215px;
}

.mb-220,
.my-220 {
  margin-bottom: 220px;
}

.mb-225,
.my-225 {
  margin-bottom: 225px;
}

.mb-230,
.my-230 {
  margin-bottom: 230px;
}

.mb-235,
.my-235 {
  margin-bottom: 235px;
}

.mb-240,
.my-240 {
  margin-bottom: 240px;
}

.mb-245,
.my-245 {
  margin-bottom: 245px;
}

.mb-250,
.my-250 {
  margin-bottom: 250px;
}

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {

  /* Padding Around */
  .rp-0 {
    padding: 0px !important;
  }

  .rp-5 {
    padding: 5px !important;
  }

  .rp-10 {
    padding: 10px;
  }

  .rp-15 {
    padding: 15px;
  }

  .rp-20 {
    padding: 20px;
  }

  .rp-25 {
    padding: 25px;
  }

  .rp-30 {
    padding: 30px;
  }

  .rp-35 {
    padding: 35px;
  }

  .rp-40 {
    padding: 40px;
  }

  .rp-45 {
    padding: 45px;
  }

  .rp-50 {
    padding: 50px;
  }

  .rp-55 {
    padding: 55px;
  }

  .rp-60 {
    padding: 60px;
  }

  .rp-65 {
    padding: 65px;
  }

  .rp-70 {
    padding: 70px;
  }

  .rp-75 {
    padding: 75px;
  }

  .rp-80 {
    padding: 80px;
  }

  .rp-85 {
    padding: 85px;
  }

  .rp-90 {
    padding: 90px;
  }

  .rp-95 {
    padding: 95px;
  }

  .rp-100 {
    padding: 100px;
  }

  .rp-105 {
    padding: 105px;
  }

  .rp-110 {
    padding: 110px;
  }

  .rp-115 {
    padding: 115px;
  }

  .rp-120 {
    padding: 120px;
  }

  .rp-125 {
    padding: 125px;
  }

  .rp-130 {
    padding: 130px;
  }

  .rp-135 {
    padding: 135px;
  }

  .rp-140 {
    padding: 140px;
  }

  .rp-145 {
    padding: 145px;
  }

  .rp-150 {
    padding: 150px;
  }

  /* Padding Top */
  .rpt-0,
  .rpy-0 {
    padding-top: 0px !important;
  }

  .rpt-5,
  .rpy-5 {
    padding-top: 5px !important;
  }

  .rpt-10,
  .rpy-10 {
    padding-top: 10px;
  }

  .rpt-15,
  .rpy-15 {
    padding-top: 15px;
  }

  .rpt-20,
  .rpy-20 {
    padding-top: 20px;
  }

  .rpt-25,
  .rpy-25 {
    padding-top: 25px;
  }

  .rpt-30,
  .rpy-30 {
    padding-top: 30px;
  }

  .rpt-35,
  .rpy-35 {
    padding-top: 35px;
  }

  .rpt-40,
  .rpy-40 {
    padding-top: 40px;
  }

  .rpt-45,
  .rpy-45 {
    padding-top: 45px;
  }

  .rpt-50,
  .rpy-50 {
    padding-top: 50px;
  }

  .rpt-55,
  .rpy-55 {
    padding-top: 55px;
  }

  .rpt-60,
  .rpy-60 {
    padding-top: 60px;
  }

  .rpt-65,
  .rpy-65 {
    padding-top: 65px;
  }

  .rpt-70,
  .rpy-70 {
    padding-top: 70px;
  }

  .rpt-75,
  .rpy-75 {
    padding-top: 75px;
  }

  .rpt-80,
  .rpy-80 {
    padding-top: 80px;
  }

  .rpt-85,
  .rpy-85 {
    padding-top: 85px;
  }

  .rpt-90,
  .rpy-90 {
    padding-top: 90px;
  }

  .rpt-95,
  .rpy-95 {
    padding-top: 95px;
  }

  .rpt-100,
  .rpy-100 {
    padding-top: 100px;
  }

  .rpt-105,
  .rpy-105 {
    padding-top: 105px;
  }

  .rpt-110,
  .rpy-110 {
    padding-top: 110px;
  }

  .rpt-115,
  .rpy-115 {
    padding-top: 115px;
  }

  .rpt-120,
  .rpy-120 {
    padding-top: 120px;
  }

  .rpt-125,
  .rpy-125 {
    padding-top: 125px;
  }

  .rpt-130,
  .rpy-130 {
    padding-top: 130px;
  }

  .rpt-135,
  .rpy-135 {
    padding-top: 135px;
  }

  .ms-xl-auto .rpt-140,
  .rpy-140 {
    padding-top: 140px;
  }

  .rpt-145,
  .rpy-145 {
    padding-top: 145px;
  }

  .rpt-150,
  .rpy-150 {
    padding-top: 150px;
  }

  /* Padding Bottom */
  .rpb-0,
  .rpy-0 {
    padding-bottom: 0px !important;
  }

  .rpb-5,
  .rpy-5 {
    padding-bottom: 5px !important;
  }

  .rpb-10,
  .rpy-10 {
    padding-bottom: 10px;
  }

  .rpb-15,
  .rpy-15 {
    padding-bottom: 15px;
  }

  .rpb-20,
  .rpy-20 {
    padding-bottom: 20px;
  }

  .rpb-25,
  .rpy-25 {
    padding-bottom: 25px;
  }

  .rpb-30,
  .rpy-30 {
    padding-bottom: 30px;
  }

  .rpb-35,
  .rpy-35 {
    padding-bottom: 35px;
  }

  .rpb-40,
  .rpy-40 {
    padding-bottom: 40px;
  }

  .rpb-45,
  .rpy-45 {
    padding-bottom: 45px;
  }

  .rpb-50,
  .rpy-50 {
    padding-bottom: 50px;
  }

  .rpb-55,
  .rpy-55 {
    padding-bottom: 55px;
  }

  .rpb-60,
  .rpy-60 {
    padding-bottom: 60px;
  }

  .rpb-65,
  .rpy-65 {
    padding-bottom: 65px;
  }

  .rpb-70,
  .rpy-70 {
    padding-bottom: 70px;
  }

  .rpb-75,
  .rpy-75 {
    padding-bottom: 75px;
  }

  .rpb-80,
  .rpy-80 {
    padding-bottom: 80px;
  }

  .rpb-85,
  .rpy-85 {
    padding-bottom: 85px;
  }

  .rpb-90,
  .rpy-90 {
    padding-bottom: 90px;
  }

  .rpb-95,
  .rpy-95 {
    padding-bottom: 95px;
  }

  .rpb-100,
  .rpy-100 {
    padding-bottom: 100px;
  }

  .rpb-105,
  .rpy-105 {
    padding-bottom: 105px;
  }

  .rpb-110,
  .rpy-110 {
    padding-bottom: 110px;
  }

  .rpb-115,
  .rpy-115 {
    padding-bottom: 115px;
  }

  .rpb-120,
  .rpy-120 {
    padding-bottom: 120px;
  }

  .rpb-125,
  .rpy-125 {
    padding-bottom: 125px;
  }

  .rpb-130,
  .rpy-130 {
    padding-bottom: 130px;
  }

  .rpb-135,
  .rpy-135 {
    padding-bottom: 135px;
  }

  .rpb-140,
  .rpy-140 {
    padding-bottom: 140px;
  }

  .rpb-145,
  .rpy-145 {
    padding-bottom: 145px;
  }

  .rpb-150,
  .rpy-150 {
    padding-bottom: 150px;
  }

  /* Margin Around */
  .rm-0 {
    margin: 0px !important;
  }

  .rm-5 {
    margin: 5px !important;
  }

  .rm-10 {
    margin: 10px;
  }

  .rm-15 {
    margin: 15px;
  }

  .rm-20 {
    margin: 20px;
  }

  .rm-25 {
    margin: 25px;
  }

  .rm-30 {
    margin: 30px;
  }

  .rm-35 {
    margin: 35px;
  }

  .rm-40 {
    margin: 40px;
  }

  .rm-45 {
    margin: 45px;
  }

  .rm-50 {
    margin: 50px;
  }

  .rm-55 {
    margin: 55px;
  }

  .rm-60 {
    margin: 60px;
  }

  .rm-65 {
    margin: 65px;
  }

  .rm-70 {
    margin: 70px;
  }

  .rm-75 {
    margin: 75px;
  }

  .rm-80 {
    margin: 80px;
  }

  .rm-85 {
    margin: 85px;
  }

  .rm-90 {
    margin: 90px;
  }

  .rm-95 {
    margin: 95px;
  }

  .rm-100 {
    margin: 100px;
  }

  .rm-105 {
    margin: 105px;
  }

  .rm-110 {
    margin: 110px;
  }

  .rm-115 {
    margin: 115px;
  }

  .rm-120 {
    margin: 120px;
  }

  .rm-125 {
    margin: 125px;
  }

  .rm-130 {
    margin: 130px;
  }

  .rm-135 {
    margin: 135px;
  }

  .rm-140 {
    margin: 140px;
  }

  .rm-145 {
    margin: 145px;
  }

  .rm-150 {
    margin: 150px;
  }

  /* Margin Top */
  .rmt-0,
  .rmy-0 {
    margin-top: 0px !important;
  }

  .rmt-5,
  .rmy-5 {
    margin-top: 5px !important;
  }

  .rmt-10,
  .rmy-10 {
    margin-top: 10px;
  }

  .rmt-15,
  .rmy-15 {
    margin-top: 15px;
  }

  .rmt-20,
  .rmy-20 {
    margin-top: 20px;
  }

  .rmt-25,
  .rmy-25 {
    margin-top: 25px;
  }

  .rmt-30,
  .rmy-30 {
    margin-top: 30px;
  }

  .rmt-35,
  .rmy-35 {
    margin-top: 35px;
  }

  .rmt-40,
  .rmy-40 {
    margin-top: 40px;
  }

  .rmt-45,
  .rmy-45 {
    margin-top: 45px;
  }

  .rmt-50,
  .rmy-50 {
    margin-top: 50px;
  }

  .rmt-55,
  .rmy-55 {
    margin-top: 55px;
  }

  .rmt-60,
  .rmy-60 {
    margin-top: 60px;
  }

  .rmt-65,
  .rmy-65 {
    margin-top: 65px;
  }

  .rmt-70,
  .rmy-70 {
    margin-top: 70px;
  }

  .rmt-75,
  .rmy-75 {
    margin-top: 75px;
  }

  .rmt-80,
  .rmy-80 {
    margin-top: 80px;
  }

  .rmt-85,
  .rmy-85 {
    margin-top: 85px;
  }

  .rmt-90,
  .rmy-90 {
    margin-top: 90px;
  }

  .rmt-95,
  .rmy-95 {
    margin-top: 95px;
  }

  .rmt-100,
  .rmy-100 {
    margin-top: 100px;
  }

  .rmt-105,
  .rmy-105 {
    margin-top: 105px;
  }

  .rmt-110,
  .rmy-110 {
    margin-top: 110px;
  }

  .rmt-115,
  .rmy-115 {
    margin-top: 115px;
  }

  .rmt-120,
  .rmy-120 {
    margin-top: 120px;
  }

  .rmt-125,
  .rmy-125 {
    margin-top: 125px;
  }

  .rmt-130,
  .rmy-130 {
    margin-top: 130px;
  }

  .rmt-135,
  .rmy-135 {
    margin-top: 135px;
  }

  .rmt-140,
  .rmy-140 {
    margin-top: 140px;
  }

  .rmt-145,
  .rmy-145 {
    margin-top: 145px;
  }

  .rmt-150,
  .rmy-150 {
    margin-top: 150px;
  }

  /* Margin Bottom */
  .rmb-0,
  .rmy-0 {
    margin-bottom: 0px !important;
  }

  .rmb-5,
  .rmy-5 {
    margin-bottom: 5px !important;
  }

  .rmb-10,
  .rmy-10 {
    margin-bottom: 10px;
  }

  .rmb-15,
  .rmy-15 {
    margin-bottom: 15px;
  }

  .rmb-20,
  .rmy-20 {
    margin-bottom: 20px;
  }

  .rmb-25,
  .rmy-25 {
    margin-bottom: 25px;
  }

  .rmb-30,
  .rmy-30 {
    margin-bottom: 30px;
  }

  .rmb-35,
  .rmy-35 {
    margin-bottom: 35px;
  }

  .rmb-40,
  .rmy-40 {
    margin-bottom: 40px;
  }

  .rmb-45,
  .rmy-45 {
    margin-bottom: 45px;
  }

  .rmb-50,
  .rmy-50 {
    margin-bottom: 50px;
  }

  .rmb-55,
  .rmy-55 {
    margin-bottom: 55px;
  }

  .rmb-60,
  .rmy-60 {
    margin-bottom: 60px;
  }

  .rmb-65,
  .rmy-65 {
    margin-bottom: 65px;
  }

  .rmb-70,
  .rmy-70 {
    margin-bottom: 70px;
  }

  .rmb-75,
  .rmy-75 {
    margin-bottom: 75px;
  }

  .rmb-80,
  .rmy-80 {
    margin-bottom: 80px;
  }

  .rmb-85,
  .rmy-85 {
    margin-bottom: 85px;
  }

  .rmb-90,
  .rmy-90 {
    margin-bottom: 90px;
  }

  .rmb-95,
  .rmy-95 {
    margin-bottom: 95px;
  }

  .rmb-100,
  .rmy-100 {
    margin-bottom: 100px;
  }

  .rmb-105,
  .rmy-105 {
    margin-bottom: 105px;
  }

  .rmb-110,
  .rmy-110 {
    margin-bottom: 110px;
  }

  .rmb-115,
  .rmy-115 {
    margin-bottom: 115px;
  }

  .rmb-120,
  .rmy-120 {
    margin-bottom: 120px;
  }

  .rmb-125,
  .rmy-125 {
    margin-bottom: 125px;
  }

  .rmb-130,
  .rmy-130 {
    margin-bottom: 130px;
  }

  .rmb-135,
  .rmy-135 {
    margin-bottom: 135px;
  }

  .rmb-140,
  .rmy-140 {
    margin-bottom: 140px;
  }

  .rmb-145,
  .rmy-145 {
    margin-bottom: 145px;
  }

  .rmb-150,
  .rmy-150 {
    margin-bottom: 150px;
  }
}

/*******************************************************/
/***************** ## Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

/* Rotated Circle */
@-webkit-keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* Feedback Rotated */
@-webkit-keyframes semi_rotated {

  0%,
  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@keyframes semi_rotated {

  0%,
  100% {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

/* BG Shape Rotated */
@-webkit-keyframes semi_rotated_two {

  0%,
  100% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@keyframes semi_rotated_two {

  0%,
  100% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@-webkit-keyframes move_arround {
  0% {
    top: 20px;
    left: 20px;
  }

  25% {
    top: 20px;
    left: -20px;
  }

  50% {
    top: -20px;
    left: -20px;
  }

  75% {
    top: -20px;
    left: 20px;
  }

  100% {
    top: 20px;
    left: 20px;
  }
}

@keyframes move_arround {
  0% {
    top: 20px;
    left: 20px;
  }

  25% {
    top: 20px;
    left: -20px;
  }

  50% {
    top: -20px;
    left: -20px;
  }

  75% {
    top: -20px;
    left: 20px;
  }

  100% {
    top: 20px;
    left: 20px;
  }
}

/* Hero Circle */
@-webkit-keyframes upDownLeft {

  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }

  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px);
  }
}

@keyframes upDownLeft {

  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }

  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px);
  }
}

@-webkit-keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(0, -25px);
    transform: rotateX(0deg) translate(0, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(0, -25px);
    transform: rotateX(0deg) translate(0, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@-webkit-keyframes leftRightOne {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@keyframes leftRightOne {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@-webkit-keyframes zoomInOut {

  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes zoomInOut {

  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

/*******************************************************/
/******************* ## Header style ******************/
/*******************************************************/
.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header .header-upper {
  z-index: 5;
  width: 100%;
  position: relative;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.logo-outer img {
  width: 200px;
}

.main-header .logo-outer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}


.main-header .logo {
  z-index: 9;
  padding: 2px 0;
  position: relative;
}

.main-header.menu-absolute .header-upper {
  position: absolute;
}

.main-header.fixed-header .header-upper {
  top: 0;
  left: 0;
  position: fixed;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}

@media only screen and (min-width: 992px) {
  .main-header.fixed-header .main-menu .navbar-collapse>ul>li {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top-wrap {
    display: none;
  }
}

.header-top>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-top>ul>li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-top>ul>li:not(:last-child):after {
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 30px;
  background: var(--base-color);
}

.header-top>ul>li>i {
  margin-right: 10px;
  color: var(--primary-color);
}

.header-top .text {
  color: white;
  font-weight: 500;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.header-top .text .hello {
  padding: 4px 7px;
  font-size: 14px;
  font-weight: 700;
  background: white;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
  color: var(--secondary-color);
}

.header-top .text .hello:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 6px;
  border-top: 5px solid white;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.header-top .text a {
  text-decoration: underline;
}

.header-top .text a:not(:hover) {
  color: white;
}

@media only screen and (max-width: 991px) {
  .nav-outer {
    width: 100%;
  }
}

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%;
  }
}

.main-menu .mobile-logo {
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .main-menu .mobile-logo {
    max-width: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto;
  }
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navbar-collapse.mobile {
  padding: 0px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navbar-collapse>ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* .main-menu .navbar-collapse>ul .service-tab:hover .main-menu .navbar-collapse>ul .service-dropdown {
  display: block !important;
} */
.main-menu li.dropdown:hover>ul {
  display: block;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse>ul {
    display: block;
    padding: 0;
    /* padding-bottom: 25px; */
    background: #FFFFFF;
  }

  .main-menu .navbar-collapse>ul>li:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse {
    left: 0;
    width: 100%;
    position: absolute;
    max-height: calc(100vh - 80px);
  }
}

.main-menu .navbar-collapse li {
  padding: 35px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1399px) {
  .main-menu .navbar-collapse li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li {
    display: block;
    padding: 0 15px;
    border-top: 1px solid #f3f3f3;
  }
}

.main-menu .navbar-collapse li.dropdown .dropdown-btn {
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  color: var(--heading-color);
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 50px;
    height: 43px;
    border-left: 1px solid #f2f2f2;
    text-align: center;
    line-height: 43px;
  }
}

.main-menu .navbar-collapse li a {
  display: block;
  opacity: 1;
  font-weight: 600;
  position: relative;
  color: var(--heading-color);
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media only screen and (min-width: 376px) {
  .main-menu .navbar-collapse li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li a {
    padding: 10px 10px;
    line-height: 22px;
  }
}

.main-menu .navbar-collapse li a:hover {
  color: var(--primary-color) !important;

}

.main-menu .navbar-collapse li.current>a,
.main-menu .navbar-collapse li.current-menu-item>a {
  font-weight: 500;
}

.main-menu .navbar-collapse li li {
  border-top: 1px solid #f3f3f3;
}

.main-menu .navbar-collapse li li a {
  text-transform: capitalize;
}

.main-menu .navbar-collapse li li a:before {
  display: none;
}

.main-menu .navbar-collapse li .megamenu {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  padding: 20px 0;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navbar-collapse li .megamenu:after {
  display: block;
  clear: both;
  content: "";
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li .megamenu {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  .main-menu .navbar-collapse li .megamenu .container {
    max-width: 100%;
  }

  .main-menu .navbar-collapse li .megamenu .row {
    margin: 0px;
  }
}

.main-menu .navbar-collapse li .megamenu ul {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-menu .navbar-collapse li ul {
  position: absolute;
  left: inherit;
  top: 100%;
  min-width: 250px;
  z-index: 100;
  display: none;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul {
    position: relative;
    display: none;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-menu .navbar-collapse li ul:after {
    display: block;
    clear: both;
    content: "";
  }
}

@media only screen and (max-width: 991px) and (max-width: 375px) {
  .main-menu .navbar-collapse li ul {
    min-width: auto;
  }
}

.main-menu .navbar-collapse li ul li {
  width: 100%;
  padding: 7px 20px;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li {
    padding: 0 15px;
  }
}

.main-menu .navbar-collapse li ul li ul {
  left: 100%;
  top: 0%;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-collapse li ul li ul {
    left: auto;
  }
}

.main-menu .navbar-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .main-menu .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
}

.main-menu .navbar-header .navbar-toggle {
  float: right;
  padding: 4px 0;
  cursor: pointer;
  background: transparent;
}

.main-menu .navbar-header .navbar-toggle .icon-bar {
  background: var(--base-color);
  height: 2px;
  width: 30px;
  display: block;
  margin: 7px 0;
}

/* Menu Btns */
.menu-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-btns .srb-icon,
.menu-btns .eng-icon {
  position: relative;
  margin-right: 15px;
}

.menu-btns .srb-icon .icon-overlay.disabled,
.menu-btns .eng-icon .icon-overlay.disabled {
  position: absolute;
  top: 5px;
  left: 0;
  height: 17px;
  width: 22px;
  background-color: rgba(0, 0, 0, 0.5)
    /* border-radius: 10px; */
}

@media(min-width: 992px) {

  .menu-btns .srb-icon .icon-overlay.disabled,
  .menu-btns .eng-icon .icon-overlay.disabled {
    top: 4px;
  }
}



/* .menu-btns img::before {
  position: absolute;
  z-index: 3;
  content: "";
  height: 10px;
  width: 10px;
  display: block;
  background-color: rgba(0, 0, 0);
} */

.menu-btns .theme-btn {
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 5px;
  color: white;
}

@media only screen and (max-width: 1199px) {
  .menu-btns .theme-btn {
    display: none;
  }
}

/* Header Search */
.nav-search {
  position: relative;
  margin-right: 40px;
  margin-left: 25px;
}

.nav-search button {
  background: transparent;
}

.nav-search form {
  position: absolute;
  width: 320px;
  top: 100%;
  right: 0;
  z-index: 777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 375px) {
  .nav-search form {
    width: 275px;
  }
}

.nav-search form.hide {
  display: none;
}

.nav-search form input {
  border: none;
  padding: 15px 5px 15px 25px;
}

.nav-search form button {
  padding: 15px 20px 15px 0;
}

/* Header Socila */
.header-inner .social-style-two {
  margin-left: 30px;
}

@media only screen and (max-width: 1399px) {
  .header-inner .social-style-two {
    display: none;
  }
}

/* Menu Sidebar */
.menu-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
  .menu-sidebar {
    margin-left: 30px;
  }
}

.menu-sidebar button {
  width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: transparent;
}

.menu-sidebar button .icon-bar {
  background: var(--heading-color);
  height: 2px;
  width: 100%;
  margin: 4px 0;
  display: block;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.menu-sidebar button .icon-bar:first-child {
  width: calc(100% - 5px);
}

.menu-sidebar button .icon-bar:last-child {
  width: calc(100% - 8px);
}

.menu-sidebar button .toggle-btn {
  width: 25px;
  aspect-ratio: 1;
  --c: conic-gradient(from -90deg, #674df3 90deg, #0000 0);
  background: var(--c), var(--c);
  background-size: 40% 40%;
}

.menu-sidebar button:hover .icon-bar {
  width: 100%;
}

.menu-sidebar button:hover .toggle-btn {
  -webkit-animation: preloader 1s infinite alternate;
  animation: preloader 1s infinite alternate;
}

/** hidden-sidebar * */
.hidden-bar {
  position: fixed;
  right: -350px;
  top: 0px;
  opacity: 0;
  width: 350px;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
  visibility: hidden;
  background-color: var(--heading-color);
  border-left: 1px solid #231b26;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 375px) {
  .hidden-bar {
    width: 300px;
  }
}

.hidden-bar .social-style-one a {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.side-content-visible .hidden-bar {
  right: 0px;
  opacity: 1;
  visibility: visible;
}

.hidden-bar .inner-box {
  position: relative;
  padding: 100px 40px 50px;
}

@media only screen and (max-width: 375px) {
  .hidden-bar .inner-box {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.hidden-bar .inner-box .cross-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.hidden-bar .inner-box h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 35px;
}

/*Appointment Form */
.hidden-bar .appointment-form {
  position: relative;
}

.hidden-bar .appointment-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.hidden-bar .appointment-form input[type="text"],
.hidden-bar .appointment-form input[type="email"] {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hidden-bar .appointment-form textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  resize: none;
}

.hidden-bar .appointment-form input::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form .form-group button {
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-color: white;
}

.form-back-drop {
  position: fixed;
  left: 100%;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  z-index: 9990;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.side-content-visible .form-back-drop {
  opacity: 1;
  left: 0;
  visibility: visible;
}

/* Menu White */
.main-header.menu-white .nav-search>button {
  color: white;
}

@media only screen and (min-width: 992px) {

  .main-header.menu-white .main-menu .navigation>li>a,
  .main-header.menu-white .main-menu .navigation>.dropdown>.dropdown-btn {
    color: white;
  }
}

.main-header.menu-white .main-menu .navigation li li a:hover {
  color: var(--primary-color);
}

.main-header.menu-white .menu-sidebar button .icon-bar,
.main-header.menu-white .main-menu .navbar-toggle .icon-bar {
  background: white;
}

.main-header.menu-white .toggle-btn {
  --c: conic-gradient(from -90deg, white 90deg, #0000 0);
}

/* Header One */
.main-header.header-one .header-top {
  padding: 9px;
}

@media only screen and (min-width: 992px) {
  .main-header.header-one .header-top {
    margin-left: 155px;
  }
}

@media only screen and (max-width: 1299px) {
  .main-header.header-one .header-top .for-none {
    display: none;
  }
}

.main-header.header-one .logo-outer {
  margin-top: -50px;
  margin-right: 25px;
  padding: 25px 30px;
  background: var(--secondary-color);
  -webkit-clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
}

@media only screen and (min-width: 992px) {
  .main-header.header-one .main-menu .navigation>li {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.main-header.header-one .mobile-logo {
  padding: 10px 25px;
  background: var(--secondary-color);
  -webkit-clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
}

.main-header.header-one .mobile-logo img {
  max-height: 50px;
}

@media only screen and (max-width: 1199px) {
  .main-header.header-one .nav-search {
    margin-right: 0;
  }
}

.main-header.header-one.fixed-header .logo-outer {
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.main-header.header-one.fixed-header .logo-outer .logo img {
  max-height: 60px;
}

/* Header Two */
@media only screen and (max-width: 375px) {
  .main-header.header-two .main-menu .mobile-logo {
    max-width: 135px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-header.header-two .nav-search {
    margin-right: 0;
  }
}

/* Header Three */
.main-header.header-three.fixed-header .header-upper {
  background: #1B2D58;
  box-shadow: 10px 0 60px #294485;
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
  .main-header.header-three .nav-search {
    margin-right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .main-header.header-three .nav-search {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .main-header.header-three .menu-btns {
    margin-left: 25px;
  }
}

/* Header Four */
@media only screen and (min-width: 992px) {
  .main-header.header-four .main-menu .navbar-collapse>ul>li {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
  .main-header.header-four .nav-search {
    margin-right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .main-header.header-four .nav-search {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .main-header.header-four .menu-btns {
    margin-left: 25px;
  }
}

/*******************************************************/
/******************** ## Hero Area ********************/
/*******************************************************/
.hero-content {
  z-index: 2;
  max-width: 530px;
  position: relative;
}

.hero-content .sub-title {
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 0 5px 5px 0;
  background: var(--primary-color);
  border-left: 3px solid white;
}

@media only screen and (min-width: 768px) {
  .hero-content .sub-title {
    font-size: 18px;
  }
}

.hero-content h1 {
  color: white;
  font-size: 65px;
}

@media only screen and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-content h1 {
    font-size: 33px;
    line-height: 1.3;
  }
}

.hero-content p {
  color: white;
  margin-top: 22px;
}

@media only screen and (min-width: 1200px) {
  .hero-content p {
    font-size: 18px;
  }
}

.hero-images {
  z-index: 1;
  position: relative;
  margin-bottom: -80px;
}

@media only screen and (min-width: 1400px) {
  .hero-images {
    margin-left: -8%;
    margin-right: -25%;
  }
}

.hero-images img {
  -webkit-clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
}

.hero-shapes .shape {
  z-index: -1;
  position: absolute;
}

.hero-shapes .shape.bg-lines {
  left: -25px;
  top: -50px;
  max-width: 55%;
  -webkit-animation: down-up-two 10s infinite;
  animation: down-up-two 10s infinite;
}

.hero-shapes .shape.right-shape {
  right: 0;
  z-index: 2;
  bottom: -5%;
  max-width: 30%;
}

.hero-shapes .shape.one {
  left: -10%;
  bottom: 0;
  -webkit-animation: leftRightOne 15s infinite;
  animation: leftRightOne 15s infinite;
}

.hero-shapes .shape.two {
  top: 0;
  right: 0;
  -webkit-animation: down-up-two 8s infinite;
  animation: down-up-two 8s infinite;
}

.hero-shapes .shape.three {
  right: 0;
  bottom: 5%;
  -webkit-animation: shapeAnimationFour 20s infinite;
  animation: shapeAnimationFour 20s infinite;
}

/* Hero Two */
.hero-area-two {
  overflow: hidden;
  z-index: 1;
  position: relative;
  background-image: url('/src/hero-two.webp');
  background-size: cover;
}

.hero-area-two.erp {
  background-image: url('/src/erp.webp');
}

.hero-area-two.services {
  background-image: url('/src/services.webp');
}

.hero-area-two::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: rgba(0, 0, 0, 0.3)
}

.project-page.container {
  margin-top: 130px;
  margin-bottom: 130px;
}

@media(max-width: 768px) {
  .project-page.container {
    margin-top: 0;
  }
}

.hero-area-two.small-business {
  background-image: url('/src/small_business_bg.jpeg');
}



.hero-area-two .hero-shapes-two {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-width: 30%;
  -webkit-animation: down-up-two 10s infinite;
  animation: down-up-two 10s infinite;
}

.hero-content-two .sub-title {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 576px) {
  .hero-content-two .sub-title {
    font-size: 22px;
  }
}

.hero-content-two .sub-title:before {
  content: '';
  width: 60px;
  height: 2px;
  margin-right: 20px;
  background: white;
}

@media only screen and (max-width: 375px) {
  .hero-content-two .sub-title:before {
    width: 40px;
    height: 1px;
  }
}

.hero-content-two h1 {
  color: white;
}

@media only screen and (max-width: 1399px) {
  .hero-content-two h1 {
    font-size: 65px;
  }
}

@media only screen and (max-width: 1199px) {
  .hero-content-two h1 {
    font-size: 55px;
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content-two h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content-two h1 {
    font-size: 38px;
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-content-two h1 {
    font-size: 30px;
  }
}

.hero-gradient-circle {
  position: relative;
  padding-bottom: 100%;
}

@media only screen and (max-width: 991px) {
  .hero-gradient-circle {
    width: 200px;
    top: 22%;
    right: 5%;
    z-index: -1;
    position: absolute;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-gradient-circle {
    top: 15%;
  }
}

@media only screen and (max-width: 479px) {
  .hero-gradient-circle {
    width: 150px;
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-gradient-circle {
    top: 15%;
  }
}

.hero-gradient-circle .icon {
  z-index: 2;
  position: absolute;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  position: absolute;
  right: calc(50% - 50px);
  bottom: calc(50% - 50px);
  -webkit-animation: rotated_circle 10s infinite;
  animation: rotated_circle 10s infinite;
}

@media only screen and (max-width: 991px) {
  .hero-gradient-circle .icon {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    position: absolute;
    right: calc(50% - 25px);
    bottom: calc(50% - 25px);
  }
}

.hero-gradient-circle:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.75;
  border-radius: 50%;
  background: -webkit-gradient(linear, right top, left top, from(var(--primary-color)), to(var(--secondary-color)));
  background: -webkit-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: linear-gradient(-90deg, var(--primary-color), var(--secondary-color));
  -webkit-animation: rotated_circle 10s infinite;
  animation: rotated_circle 10s infinite;
}

.hero-gradient-circle:after {
  content: '';
  width: 56%;
  height: 56%;
  position: absolute;
  right: 22%;
  top: 22%;
  background: white;
  border-radius: 50%;
}

/*******************************************************/
/******************* ## Page Banner *******************/
/*******************************************************/
.page-banner-area {
  z-index: 1;
  position: relative;
}

.page-banner-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background-color: var(--heading-color);
}

.page-banner-area:before {
  mix-blend-mode: multiply;
}

.banner-inner h1 {
  font-size: 75px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .banner-inner h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-inner h1 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-inner h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 375px) {
  .banner-inner h1 {
    font-size: 35px;
    line-height: 1.25;
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
  font-size: 22px;
  background: transparent;
  text-transform: capitalize;
}

@media only screen and (max-width: 375px) {
  .breadcrumb {
    font-size: 18px;
  }
}

.breadcrumb .breadcrumb-item {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.breadcrumb .breadcrumb-item.active {
  color: white;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  margin-left: 15px;
  margin-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: white;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
}

/*******************************************************/
/******************* ## Main Slider *******************/
/*******************************************************/
.slider-area {
  z-index: 1;
  position: relative;
}

.slider-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.65;
  background-color: var(--heading-color);
}

.slider-area:before {
  mix-blend-mode: multiply;
}

.slider-area:after {
  width: 40%;
  height: 70%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.65;
  background: -webkit-gradient(linear, right top, left top, from(var(--primary-color)), to(var(--secondary-color)));
  background: -webkit-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: linear-gradient(-90deg, var(--primary-color), var(--secondary-color));
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 40% 40%, 60% 55%);
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 40% 40%, 60% 55%);
}

.slide-content {
  max-width: 515px;
}

.slide-content .sub-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) {
  .slide-content .sub-title {
    font-size: 22px;
  }
}

.slide-content .sub-title:before {
  content: '';
  width: 60px;
  height: 2px;
  margin-right: 20px;
  background: white;
}

@media only screen and (max-width: 375px) {
  .slide-content .sub-title:before {
    width: 40px;
    height: 1px;
  }
}

.slide-content .h2 {
  display: block;
  font-size: 85px;
  font-weight: 300;
  letter-spacing: -3px;
}

@media only screen and (max-width: 1199px) {
  .slide-content .h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slide-content .h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slide-content .h2 {
    font-size: 43px;
  }
}

@media only screen and (max-width: 375px) {
  .slide-content .h2 {
    font-size: 35px;
  }
}

.slide-content h1 {
  line-height: 1;
  font-size: 100px;
  margin-bottom: 60px;
  letter-spacing: -2px;
}

@media only screen and (max-width: 1399px) {
  .slide-content h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .slide-content h1 {
    font-size: 55px;
    line-height: 1.3;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .slide-content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .slide-content h1 {
    font-size: 38px;
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 375px) {
  .slide-content h1 {
    font-size: 30px;
  }
}

.slide-content>* {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.main-slider-active .slick-arrow {
  position: absolute;
  right: 0;
  z-index: 2;
  color: white;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 479px) {
  .main-slider-active .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.main-slider-active .slick-arrow.next-arrow {
  top: 25%;
}

.main-slider-active .slick-arrow.prev-arrow {
  top: calc(25% + 80px);
}

.main-slider-active .slick-arrow:focus,
.main-slider-active .slick-arrow:hover {
  /* border-color: white; */
}

.slick-active .slide-content * {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.slick-active .slide-content .sub-title {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.slick-active .slide-content .h2 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.slick-active .slide-content h1 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.slick-active .slide-content .theme-btn {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/* Slider Area Two */
.slider-area-two {
  overflow: hidden;
}

.slider-area-two:after {
  width: 40%;
  height: 70%;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.65;
  background: -webkit-gradient(linear, right top, left top, from(var(--primary-color)), to(var(--secondary-color)));
  background: -webkit-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: linear-gradient(-90deg, var(--primary-color), var(--secondary-color));
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 40% 40%, 60% 55%);
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 40% 40%, 60% 55%);
}

.slider-area-two .slider-bg-line {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-width: 33%;
}

.slider-area-two .slider-bg-text {
  font-size: 16vw;
  position: absolute;
  left: 5%;
  bottom: 5%;
  z-index: -1;
  opacity: 0.05;
  line-height: 1;
}

.slider-item-two {
  z-index: 1;
  position: relative;
  padding-top: 130px;
  padding-bottom: 210px;
}

.slider-item-two .slide-content {
  position: relative;
  z-index: 2;
}

.slider-item-two .sub-title {
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.slider-item-two .sub-title:before {
  background: var(--secondary-color);
}

.slider-item-two h1 {
  margin-bottom: 45px;
}

@media only screen and (max-width: 1199px) {
  .slider-item-two h1 {
    margin-bottom: 15px;
  }
}

.slider-item-two .theme-btn:not(:last-child) {
  margin-right: 25px;
}

.slider-item-two .slider-image {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 45%;
  height: 100%;
  background-size: cover;
  background-color: var(--heading-color);
  background-position: center center;
}

@media only screen and (max-width: 991px) {
  .slider-item-two .slider-image {
    width: 100%;
    z-index: 1;
    position: relative;
    position: absolute;
  }

  .slider-item-two .slider-image::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.9;
    background-color: white;
  }
}

.slider-arrows {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
}

.slider-arrows .slick-arrow {
  position: absolute;
  right: 15px;
  z-index: 2;
}

@media only screen and (min-width: 992px) {
  .slider-arrows .slick-arrow {
    color: white;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

@media only screen and (max-width: 479px) {
  .slider-arrows .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.slider-arrows .slick-arrow:focus,
.slider-arrows .slick-arrow:hover {
  border-color: white;
}

.slider-arrows .slick-arrow.next-slider {
  bottom: calc(100% + 10px);
}

.slider-arrows .slick-arrow.prev-slider {
  top: calc(100% + 10px);
}

.slick-active .slider-image {
  opacity: 1;
}

/*******************************************************/
/******************* ## About Area ********************/
/*******************************************************/
.about-content p {
  max-width: 545px;
}

.about-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-btns .theme-btn:not(:last-child) {
  margin-right: 30px;
}

.hotline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hotline>i {
  width: 55px;
  height: 55px;
  line-height: 51px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
  color: var(--secondary-color);
  border: 2px solid var(--border-color);
}

.hotline .content {
  line-height: 1.65;
}

.hotline .content span {
  font-weight: 500;
}

.hotline .content a {
  font-size: 18px;
  font-weight: 700;
}

.hotline .content a:not(:hover) {
  color: var(--heading-color);
}

.about-images .top-part,
.about-images .bottom-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about-images .top-part img,
.about-images .bottom-part img {
  margin-left: 15px;
  margin-right: 15px;
}

.about-images .top-part {
  margin-bottom: 30px;
}

.about-images .top-part img:first-child {
  max-width: calc(68% - 30px);
}

.about-images .top-part img:last-child {
  margin-top: 50px;
  margin-left: auto;
  max-width: calc(30% - 30px);
}

.about-images .bottom-part img:first-child {
  width: calc(33% - 30px);
}

.about-images .bottom-part img:last-child {
  margin-top: -30%;
  width: calc(67% - 30px);
}

/* About Two Image */
.about-two-image .bg-circle-shape {
  width: 405px;
  height: 405px;
  z-index: -1;
  left: -200px;
  bottom: -150px;
  position: absolute;
  border-radius: 50%;
  background: -webkit-gradient(linear, right top, left top, from(var(--primary-color)), to(var(--secondary-color)));
  background: -webkit-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--primary-color), var(--secondary-color));
  background: linear-gradient(-90deg, var(--primary-color), var(--secondary-color));
  -webkit-animation: rotated_circle 10s infinite;
  animation: rotated_circle 10s infinite;
}

@media only screen and (max-width: 1399px) {
  .about-two-image .bg-circle-shape {
    width: 200px;
    height: 200px;
    left: -100px;
    bottom: -50px;
  }
}

.about-two-image .bg-circle-shape:after {
  content: '';
  width: 56%;
  height: 56%;
  position: absolute;
  right: 22%;
  top: 22%;
  background: white;
  border-radius: 50%;
}

.customer-satisfaction-area {
  z-index: 1;
  position: relative;
}

.customer-satisfaction-area .image {
  position: relative;
}

.customer-satisfaction-area .home-about-image-two {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.8s;
}

.customer-satisfaction-area .home-about-image-two:hover {
  opacity: 1;
}



.customer-satisfaction-area:before {
  top: -35%;
}

.cs-image-part {
  position: relative;
  padding-left: 40px;
}

@media only screen and (min-width: 992px) {
  .cs-image-part {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 575px) {
  .cs-image-part {
    padding-left: 20px;
  }
}

.cs-image-part .video-play {
  left: 0;
  position: absolute;
  top: calc(50% - 40px);
}

.cs-image-part .image {
  max-width: 75%;
}

.cs-image-part .image img {
  border-radius: 10px;
}

.cs-image-part .experience-years {
  position: absolute;
  right: 0;
  top: 13%;
  max-width: 260px;
  padding: 35px 40px 40px;
  background: var(--secondary-color);
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .cs-image-part .experience-years {
    padding: 20px 25px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .cs-image-part .experience-years {
    top: 25%;
    max-width: 180px;
    padding-bottom: 5px;
  }
}

.cs-image-part .experience-years h4 {
  line-height: 1.54;
  margin-bottom: 18px;
}

@media only screen and (min-width: 768px) {
  .cs-image-part .experience-years h4 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 375px) {
  .cs-image-part .experience-years h4 {
    font-size: 18px;
  }
}

.cs-image-part .experience-years img {
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .cs-image-part .experience-years img {
    display: none;
  }
}

/* Why Choose Us */
.why-choose-tab .nav .nav-link {
  font-size: 24px;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
  border-bottom: 1px solid var(--border-color);
}

.why-choose-tab .icon {
  height: 70px;
}

@media only screen and (min-width: 1200px) {
  .why-choose-tab .nav .nav-link {
    padding-bottom: 25px;
  }
}

.why-choose-tab .nav .nav-link i {
  line-height: 1;
  font-size: 45px;
  margin-right: 15px;
  color: var(--secondary-color);
}

@media only screen and (max-width: 767px) {
  .why-choose-tab .nav .nav-link {
    font-size: 20px;
  }

  .why-choose-tab .nav .nav-link i {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .why-choose-tab .nav .nav-link {
    font-size: 16px;
  }

  .why-choose-tab .nav .nav-link i {
    font-size: 20px;
  }
}

.why-choose-tab .nav .nav-link.active {
  border-bottom-color: var(--secondary-color);
}

.why-choose-image {
  position: relative;
  z-index: 1;
}

.why-choose-image:before {
  content: '';
  z-index: -1;
  position: absolute;
  left: -30px;
  bottom: -30px;
  width: 86px;
  height: 186px;
  /* background: url(../images/about/why-choose-image-bg-dots.png) no-repeat center/cover; */
}

.why-choose-content h3 {
  font-size: 30px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 575px) {
  .why-choose-content h3 {
    font-size: 25px;
  }
}

.why-choose-content .list-style-one li {
  font-weight: 600;
}

.why-choose-content .list-style-one li:not(:last-child) {
  margin-bottom: 10px;
}

/* .why-choose-content .list-style-one li:before {
  width: 25px;
  height: 25px;
  color: white;
  margin-top: 3px;
  line-height: 21px;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
} */

.why-choose-shapes .shape {
  z-index: -1;
  max-width: 33%;
  -webkit-animation: down-up-two 6s infinite;
  animation: down-up-two 6s infinite;
}

.why-choose-shapes .shape.one {
  position: absolute;
  right: 0;
  top: 0;
}

.why-choose-shapes .shape.two {
  position: absolute;
  left: 0;
  bottom: -10%;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.why-choose-shapes .shape.one,
.why-choose-shapes .shape.two {
  max-height: 550px;
}

/* About Three */
.about-bg-shape {
  position: absolute;
  z-index: -1;
  top: -75px;
  max-width: 50%;
}

/* Consultations */
.consultations-area:after {
  content: '';
  width: 100%;
  height: 22%;
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--heading-color);
}

.consultations-content .section-title {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 767px) {
  .consultations-content .section-title {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .consultations-content .consultations-bottom {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.consultations-content .consultations-bottom .theme-btn {
  color: var(--heading-color);
  background: var(--primary-color);
}

.consultations-content .consultations-bottom .theme-btn i {
  color: var(--heading-color);
}

.consultations-video {
  z-index: 1;
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
}

.consultations-video::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.2;
  background-color: #1b1f2e;
}

.consultations-video:before {
  z-index: 0;
}

.consultations-video img {
  width: 100%;
}

.consultations-video .video-play {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  color: var(--primary-color);
}

.consultations-video .video-play:hover {
  color: white;
  background: var(--primary-color);
}

/* About Four */
@media only screen and (min-width: 1400px) {
  .about-four-image {
    margin-right: 60px;
    margin-left: -100px;
  }
}

@media only screen and (max-width: 1399px) {
  .about-four-image {
    display: inline-block;
  }
}

.about-four-image .about-circle {
  margin-right: 10%;
  position: relative;
  margin-bottom: 20%;
  display: inline-block;
}

.about-four-image .about-circle .text {
  position: absolute;
  left: 7%;
  top: 7%;
  width: 86%;
  -webkit-animation: rotated_circle 30s linear infinite;
  animation: rotated_circle 30s linear infinite;
}

.about-four-image .image {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 60%;
}

.about-four-content .nav li .nav-link {
  border-radius: 0;
  border-top: 1px solid rgba(27, 31, 45, 0.15);
  border-bottom: 1px solid rgba(27, 31, 45, 0.15);
}

@media only screen and (min-width: 768px) {
  .about-four-content .nav li .nav-link {
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.about-four-content .nav li .nav-link.active {
  color: var(--secondary-color);
  background: transparent;
  border-color: var(--heading-color);
}

.about-four-content .nav li .nav-link:hover {
  color: var(--secondary-color);
}

.about-four-content .list-style-one li {
  font-weight: 600;
}

.about-four-content .list-style-one li:not(:last-child) {
  margin-bottom: 10px;
}

.about-four-content .list-style-one li:before {
  width: 25px;
  height: 25px;
  color: white;
  margin-top: 3px;
  line-height: 21px;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* About Page */
.about-five-images img {
  width: 60%;
  display: block;
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .about-five-images img {
    width: 70%;
  }
}

@media only screen and (max-width: 375px) {
  .about-five-images img {
    width: 80%;
  }
}

.about-five-images img:nth-child(2) {
  margin-top: -35%;
  margin-left: auto;
}

.about-five-images .experience-years {
  position: absolute;
  right: 13%;
  top: 13%;
  max-width: 235px;
  padding: 30px 40px 15px;
  background: var(--primary-color);
}

@media only screen and (max-width: 991px) {
  .about-five-images .experience-years {
    top: 18%;
  }
}

@media only screen and (max-width: 375px) {
  .about-five-images .experience-years {
    right: 8%;
    max-width: 166px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.about-five-images .experience-years .years {
  line-height: 1;
  font-size: 75px;
  margin-bottom: 5px;
  display: inline-block;
}

@media only screen and (max-width: 375px) {
  .about-five-images .experience-years .years {
    font-size: 55px;
  }
}

.about-five-images .experience-years h4 {
  line-height: 1.4;
  margin-bottom: 18px;
}

@media only screen and (min-width: 768px) {
  .about-five-images .experience-years h4 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .about-five-images .experience-years h4 {
    font-size: 18px;
  }
}

.about-five-images .abut-bg-shape {
  z-index: -1;
  width: 115%;
  position: absolute;
  right: 40px;
  top: -55px;
  max-width: none;
}

/*******************************************************/
/***************** ## Services Area *******************/
/*******************************************************/
.service-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 40px 35px 15px;
}

.service-item img {
  width: auto;
  height: 100px;
}

.service-item h4 {
  font-weight: 600;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  width: 100%;
}

.service-item.active {
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
}

.service-item .icon {
  margin-bottom: 25px;
}

.service-item.style-two {
  padding-top: 35px;
  background: white;
  margin-bottom: 30px;
  padding-bottom: 28px;
  -webkit-box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
}

.service-item.style-two .icon {
  line-height: 1;
  font-size: 60px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  color: var(--secondary-color);
}

.service-item.style-two:hover {
  background: var(--secondary-color);
}

.service-item.style-two:hover h4 a,
.service-item.style-two:hover .icon {
  color: white;
}

.service-item.style-three {
  padding: 0;
  margin-bottom: 30px;
}

.service-item.style-three .icon {
  line-height: 1;
  font-size: 85px;
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.service-item.style-three h4 {
  margin-bottom: 15px;
}

.service-item.style-three .read-more {
  color: var(--heading-color);
}

/* Service Style Two */
.service-two-item {
  margin-bottom: 46px;
}

@media only screen and (min-width: 376px) {
  .service-two-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.service-two-item .icon {
  font-size: 55px;
  line-height: 1;
  color: var(--secondary-color);
  margin: 5px 30px 25px 0;
}

.service-two-item .content h4 {
  margin-bottom: 15px;
}

/* Service Style Two */
.service-item-two {
  padding: 40px;
  background: white;
  margin-bottom: 30px;
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.1);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.1);
}

@media only screen and (max-width: 375px) {
  .service-item-two {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-item-two .image {
  height: 180px;
  position: relative;
  margin-bottom: 40px;
}

.service-item-two .image img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.service-item-two .content .title {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--border-color);
}

/* Service Style Three */
.service-item-three .image {
  z-index: 1;
  position: relative;
}

.service-item-three .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #21242c;
}

.service-item-three .image:before {
  z-index: 2;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.service-item-three .image img {
  width: 100%;
}

.service-item-three .image .plus {
  z-index: 3;
  opacity: 0;
  color: white;
  position: absolute;
  left: 50%;
  top: 20%;
  font-size: 65px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.service-item-three .content {
  z-index: 2;
  position: relative;
  /* margin: -40px 20px 50px 20px; */
}

.service-item-three .content {
  /* height: 350px; */
  color: #1654AF;
  /* background-color: var(--secondary-color); */
}

.service-item-three .content .top-part {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  padding: 20px;
  -webkit-box-shadow: 10px 0 40px rgba(103, 77, 243, 0.1);
  box-shadow: 10px 0 40px rgba(103, 77, 243, 0.1);
  border-radius: 0 0 10px 10px;
}

@media only screen and (max-width: 479px) {
  .service-item-three .content .top-part {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-item-three .content .top-part .icon {
  line-height: 1;
  font-size: 65px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 5px;
  color: var(--secondary-color);
}

.service-item-three .content .top-part .number {
  position: absolute;
  right: 35px;
  top: 30px;
  opacity: 0.1;
  line-height: 1;
  font-size: 150px;
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  -webkit-text-stroke-color: var(--heading-color);
}

@media only screen and (max-width: 375px) {
  .service-item-three .content .top-part h4 {
    font-size: 20px;
    line-height: 1.5;
  }
}

.service-item-three .content .bottom-part {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: -20px;
  background: white;
  padding: 0 30px 30px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-box-shadow: 10px 40px 40px rgba(103, 77, 243, 0.1);
  box-shadow: 10px 40px 40px rgba(103, 77, 243, 0.1);
}

@media only screen and (max-width: 479px) {
  .service-item-three .content .bottom-part {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-item-three .content .bottom-part p,
.service-item-three .content .bottom-part .read-more {
  color: white;
}

.service-item-three:hover .image:before,
.service-item-three.active .image:before {
  opacity: 0;
}

.service-item-three:hover .image .plus,
.service-item-three.active .image .plus {
  top: 30%;
  opacity: 1;
}

.service-item-three:hover .top-part,
.service-item-three.active .top-part {
  color: #1654AF !important;
}

.service-item-three:hover .top-part .icon,
.service-item-three:hover .top-part h4 a,
.service-item-three.active .top-part .icon,
.service-item-three.active .top-part h4 a {
  color: white;
}

.service-item-three:hover .top-part .number,
.service-item-three.active .top-part .number {
  -webkit-text-stroke-color: white;
}

.service-item-three:hover .top-part {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .service-item-three .content .top-part {
    /* background: var(--secondary-color); */
  }

  .service-item-three .content .top-part .icon,
  .service-item-three .content .top-part h4 a {
    color: white;
  }

  .service-item-three .content .top-part .number {
    -webkit-text-stroke-color: white;
  }

  .service-item-three .content .bottom-part {
    /* opacity: 1; */
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    background: var(--secondary-color);
  }
}

@media only screen and (max-width: 375px) {
  .service-item-three.slick-active .image .plus {
    font-size: 35px;
  }
}

.service-three-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.service-three-slider .service-item-three {
  margin-left: 15px;
  margin-right: 15px;
}

.service-three-slider .slick-dots {
  margin-top: 0;
}

.service-three-slider .slick-dots li {
  background: var(--secondary-color);
}

.service-three-slider .slick-dots li.slick-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  outline: 2px solid var(--primary-color);
}

/* Service Style Four */
.service-item-four {
  padding: 40px;
  background: white;
  margin-bottom: 30px;
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
}

@media only screen and (max-width: 575px) {
  .service-item-four {
    margin-top: 0;
  }
}

@media only screen and (max-width: 375px) {
  .service-item-four {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.service-item-four .icon {
  width: 93px;
  height: 93px;
  line-height: 1;
  font-size: 50px;
  line-height: 93px;
  text-align: center;
  margin-bottom: 30px;
  display: inline-block;
  color: var(--secondary-color);
  /* background: url(../images/services/icon-bg.png) no-repeat 100% 100%; */
}

.service-item-four p {
  margin: 20px 0 5px;
}

.servcies-bg-shape {
  max-width: 50%;
  z-index: -1;
  position: absolute;
  right: 0;
  top: -20%;
}

/* Service Style Five */
.services-five-active {
  margin-left: -15px;
  margin-right: -15px;
}

.services-five-active .service-item-five {
  margin-left: 15px;
  margin-right: 15px;
}

.services-five-active .slick-dots {
  margin-top: 25px;
}

.services-five-active .slick-dots li {
  background: var(--secondary-color);
}

.services-five-active .slick-dots li.slick-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  outline: 2px solid var(--primary-color);
}

.service-item-five {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}

.service-item-five:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(21, 16, 30, 0.10196)), to(#1b1f2d));
  background: -webkit-linear-gradient(rgba(21, 16, 30, 0.10196) 0%, #1b1f2d 100%);
  background: -o-linear-gradient(rgba(21, 16, 30, 0.10196) 0%, #1b1f2d 100%);
  background: linear-gradient(rgba(21, 16, 30, 0.10196) 0%, #1b1f2d 100%);
}

.service-item-five img {
  width: 100%;
}

.service-item-five .content {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  position: absolute;
  left: 0;
  bottom: -120px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 50px 30px;
}

@media only screen and (max-width: 1599px) {
  .service-item-five .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .service-item-five .content {
    bottom: 0;
  }
}

.service-item-five .content .icon {
  color: white;
  font-size: 55px;
  line-height: 85px;
  text-align: center;
  width: 100px;
  height: 85px;
  margin-bottom: 20px;
  display: inline-block;
  background: var(--secondary-color);
}

.service-item-five .content h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

.service-item-five .content h4 a {
  color: white;
}

.service-item-five .content p {
  color: white;
  margin-bottom: 10px;
}

.service-item-five .content .read-more {
  color: var(--primary-color);
}

.service-item-five .content .bottom-part {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 375px) {
  .service-item-five .content .bottom-part {
    display: none;
  }
}

.service-item-five .bg-text {
  font-size: 90px;
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 1px;
  font-family: "Poppins", sans-serif;
  -webkit-text-stroke-color: white;
  position: absolute;
  left: 50%;
  bottom: 40px;
  opacity: 0.08;
  line-height: 1;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

@media only screen and (max-width: 375px) {
  .service-item-five .bg-text {
    font-size: 60px;
  }
}

.service-item-five:hover .content {
  bottom: 0;
}

.service-item-five:hover .bottom-part {
  opacity: 1;
}

/* Service Style Six */
.service-item-six {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  margin-bottom: 30px;
  padding: 40px 20px 20px;
  background: var(--lighter-color);
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .service-item-six {
    margin-top: 0;
  }
}

@media only screen and (max-width: 375px) {
  .service-item-six {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.service-item-six .icon {
  line-height: 1;
  line-height: 1;
  font-size: 50px;
  margin-bottom: 22px;
  display: inline-block;
  color: var(--secondary-color);
}

.service-item-six h4 {
  font-size: 24px;
  margin-bottom: 15px;
}

.service-item-six:hover {
  background: white;
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
}

/* Service Details */
.service-details-content h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.service-details-content .image img {
  width: 100%;
}

/* Next Prev Service */
.next-prev-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 375px) {
  .next-prev-service {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.next-prev-service .show-all {
  margin: 0 15px 30px;
}

@media only screen and (max-width: 375px) {
  .next-prev-service .show-all {
    margin-left: auto;
    margin-right: auto;
  }
}

.next-prev-service .next-prev-item {
  margin-bottom: 30px;
}

.next-prev-service .next-prev-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .next-prev-service .next-prev-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 375px) {
  .next-prev-service .next-prev-item {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.next-prev-service .next-prev-item>div {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .next-prev-service .next-prev-item>div:first-child {
    margin-right: 45px;
  }
}

.next-prev-service .next-prev-item .image {
  max-width: 150px;
}

@media only screen and (max-width: 375px) {
  .next-prev-service .next-prev-item .image {
    width: 100%;
    max-width: none;
  }

  .next-prev-service .next-prev-item .image img {
    max-width: 150px;
  }
}

.next-prev-service .next-prev-item:last-child {
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 375px) {
  .next-prev-service .next-prev-item:last-child {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.show-all {
  width: 25px;
  height: 25px;
  aspect-ratio: 1;
  display: inline-block;
  --c: conic-gradient(from -90deg, #674df3 90deg, #0000 0);
  background: var(--c), var(--c);
  background-size: 40% 40%;
}

/*******************************************************/
/***************** ## Features Style ******************/
/*******************************************************/
.feature-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}

.services-area-three .feature-item img {
  height: 100px;
}

.feature-item .icon {
  line-height: 1;
  font-size: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.feature-item .icon img {
  max-height: 100px;
}

.feature-item h5,
.feature-item h4,
.feature-item h6,
.feature-item p {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.feature-item .more-btn {
  font-size: 18px;
  width: 43px;
  height: 40px;
  line-height: 38px;
  display: inline-block;
  border: 1px solid var(--border-color);
}

.feature-item .more-btn i {
  color: #1654AF;
}

/* .feature-item:hover {
  background: var(--secondary-color);
}

.feature-item:hover .icon,
.feature-item:hover h5,
.feature-item:hover h5 a,
.feature-item:hover h4,
.feature-item:hover p {
  color: white;
}

.feature-item:hover .more-btn,
.feature-item:hover i {
  color: #fff;
  background: var(--primary-color);
  border-color: #fff;
} */

.feature-item.style-two,
.feature-item.style-three {
  overflow: hidden;
  position: relative;
  /* padding: 35px 20px; */
  -webkit-box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
}

.feature-item.style-two.home:hover {
  background-color: var(--primary-color);
}

.feature-item.home:hover .icon,
.feature-item.home:hover h5,
.feature-item.home:hover h5 a,
.feature-item.home:hover h6,
.feature-item.home:hover p {
  color: white;
}

.feature-item.style-two:hover .feature-item.style-two h5,
.feature-item.style-two:hover .feature-item.style-two h6 {
  color: #fff !important;
}

@media only screen and (max-width: 767px) {

  .feature-item.style-two,
  .feature-item.style-three {
    margin-top: 0;
  }
}

/*  */

.feature-item.style-two .icon,
.feature-item.style-three .icon {
  margin-bottom: 20px;
}

.feature-item.style-two:hover h4 a,
.feature-item.style-three:hover h4 a,
.feature-item.style-three:hover h6 {
  color: white;
}

/* .feature-item.style-two:hover:before,
.feature-item.style-three:hover:before {
  opacity: 1;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
} */

.feature-item.style-three {
  background: white;
}

.feature-item.style-three .bg-image {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -2;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
  background-position: center center;
}

.feature-item.style-three .bg-image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.85;
  background-color: var(--secondary-color);
}

.feature-item.style-three:hover .bg-image {
  opacity: 1;
}

.feature-area-two {
  -webkit-transform: translateY(-75px);
  -ms-transform: translateY(-75px);
  transform: translateY(-75px);
  margin: 0 5%;
}

@media (max-width: 748px) {
  .feature-area-two {
    -webkit-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}


/*******************************************************/
/****************** ## Partners Area ******************/
/*******************************************************/
.partner-item {
  height: 85px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  background: var(--lighter-color);
  transition: 0.5;
}

.partner-item:hover {
  transform: translateY(-5px);
}

@media only screen and (max-width: 375px) {
  .partner-item {
    height: 65px;
  }
}

.partner-item:hover {
  background: white;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

.partners-shapes .left-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.partners-shapes .right-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
}

/* Partner Two */
.partner-item-two {
  margin-bottom: 30px;
  display: inline-block;
}

/*******************************************************/
/****************** ## Projects Area ******************/
/*******************************************************/
.project-slider-item {
  padding: 20px;
  background: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

@media only screen and (max-width: 992px) {
  .project-slider-item {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1200px) {
  .project-slider-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .project-slider-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.project-slider-item .video,
.project-slider-item .content {
  /* opacity: 0; */
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.project-slider-item .video {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin: 30px 40px;
  z-index: 1;
  position: relative;
  /* -webkit-transform: translate(50px);
  -ms-transform: translate(50px);
  transform: translate(50px); */
}

.project-slider-item .video img {
  border-radius: 10px;
}

/* .project-slider-item .video::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.3;
  background-color: #15101e;
} */

@media only screen and (max-width: 375px) {
  .project-slider-item .video {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .project-slider-item .video {
    max-width: 46%;
  }
}

.project-slider-item .video:before {
  z-index: 1;
}

.project-slider-item .video img {
  width: 100%;
}

.project-slider-item .video .video-play {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.project-slider-item .content {
  padding: 0px 30px;
}

@media(min-width: 748px) {
  .project-slider-item .content {
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 375px) {
  .project-slider-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .project-slider-item .content {
    max-width: 45%;
  }
}

.project-slider-item .content h4 {
  margin-top: -10px;
  margin-bottom: 16px;
}

@media only screen and (min-width: 480px) {
  .project-slider-item .content h4 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .project-slider-item .content h4 {
    font-size: 20px;
  }
}

.project-slider-item .content .theme-btn {
  margin-top: 30px;
}

.project-slider-item .content:first-child {
  margin-left: auto;
}

.project-slider-active {
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

/* .project-slider-active:before {
  position: absolute;
  width: 80%;
  height: 100%;
  content: '';
  left: 10%;
  top: 0;
  z-index: -1;
  background: #f0f0f3;
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
  -webkit-animation: semi_rotated_two 20s infinite;
  animation: semi_rotated_two 20s infinite;
} */

.home-project-list:after {
  position: absolute;
  width: 110vw;
  height: 30%;
  content: '';
  left: 50%;
  bottom: 0;
  z-index: -1;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  background: #1B2D58;
}

@media (max-width: 768px) {
  .home-project-list:after {
    height: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .project-slider-active .project-slider-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.project-slider-active .project-slider-item.slick-active .video,
.project-slider-active .project-slider-item.slick-active .content {
  opacity: 1;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.project-slider-active .slick-arrow {
  top: 50%;
  z-index: 2;
  position: absolute;
}

.project-slider-active .slick-arrow:hover {
  background-color: #1654AF;
  color: #fff;
}

.project-slider-active .slick-arrow.prev-arrow {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.project-slider-active .slick-arrow.next-arrow {
  right: 0;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.project-shapes {
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 70%;
  position: absolute;
}

.project-shapes .shape {
  z-index: -1;
  max-width: 35%;
  position: absolute;
}

.project-shapes .shape.one {
  left: 0;
  bottom: 10%;
  -webkit-animation: down-up-one 10s infinite;
  animation: down-up-one 10s infinite;
}

.project-shapes .shape.two {
  top: 5%;
  right: 0;
  -webkit-animation: down-up-one 10s infinite;
  animation: down-up-one 10s infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.project-shapes .shape.two,
.project-shapes .shape.one {
  max-height: 550px;
}

.contact-form-area {
  position: relative;
}

.contact-shapes {
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 70%;
  position: relative;
}

.contact-shapes .shape.one {
  position: absolute;
  left: 0;
  bottom: 10%;
  -webkit-animation: down-up-one 10s infinite;
  animation: down-up-one 10s infinite;
  max-height: 550px;
}

.contact-shapes .shape.two {
  position: absolute;
  bottom: 200px;
  right: 0;
  -webkit-animation: down-up-one 10s infinite;
  animation: down-up-one 10s infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  max-height: 550px;
}

/* Project Item */
.project-item {
  position: relative;
  margin: 0 1px 1px 0;
}

.project-item .image {
  z-index: 1;
  position: relative;
  height: 100%;
}

.project-item .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: #1B2D58;
  border-radius: 10px;
}

.project-item .image img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.project-item .image:before {
  z-index: 1;
  mix-blend-mode: multiply;
}

.project-item .content {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  width: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 40px;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

@media only screen and (max-width: 1599px) {
  .project-item .content {
    padding-left: 28px;
    padding-right: 28px;
  }
}

.project-item .content .number {
  top: 0;
  left: 50%;
  z-index: -1;
  opacity: 0.15;
  font-size: 250px;
  font-weight: 600;
  position: absolute;
  color: transparent;
  -webkit-text-stroke: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-text-stroke-color: white;
}

@media only screen and (max-width: 1399px) {
  .project-item .content .number {
    font-size: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .project-item .content .number {
    font-size: 150px;
  }
}

.project-item .content h3 {
  color: white;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
  .project-item .content h3 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .project-item .content h3 {
    font-size: 28px;
  }
}

.project-item .content p {
  color: white;
  line-height: 30px;
  margin-bottom: 25px;
}

.project-item:hover .content {
  /* opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); */
}

/* Project Item Two */
.project-item-two {
  position: relative;
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.1);
}

.project-item-two img {
  border-radius: 10px 10px 0 0
}

.project-item-two img {
  width: 100%;
}

.project-item-two .content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(35, 41, 59, 0.75);
}

.project-item-two .content img {
  height: 60px;
  width: auto;
  margin-bottom: 30px;
}

@media(max-width: 450px) {
  .project-item-two .project-description {
    font-size: 12px;
  }
}

.project-item-two .content .plus {
  opacity: 0;
  color: white;
  font-size: 75px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 15px;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.project-item-two .content h4,
.project-item-two .content h6 {
  opacity: 0;
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  line-height: 1.65;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.project-item-two .content h4 a {
  color: white;
}

.project-item-two:hover .content {
  opacity: 1;
}

.project-item-two:hover .content .plus,
.project-item-two:hover .content h4,
.project-item-two:hover .content h6 {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.project-item-two:hover .content .plus {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.project-area-three {
  color: #fff;
}

.project-area-three a {
  text-decoration: none;
}

/* Project Item Three */
.project-three-active {
  margin-left: -15px;
  margin-right: -15px;
}

.project-three-active .project-item-three {
  margin-left: 15px;
  margin-right: 15px;
}

.project-three-active .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 3;
  right: 10%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .project-three-active .slick-arrow {
    top: 35px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
  }
}

.project-three-active .slick-arrow.prev-arrow {
  left: 10%;
}

@media only screen and (max-width: 767px) {
  .project-three-active .slick-arrow.prev-arrow {
    left: 20px;
  }
}

.project-item-three {
  z-index: 1;
  position: relative;
  background-color: #4576BF;
}

.success-img {
  transition: 0.5s;
}

.project-item-three::before,
.success-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: var(--heading-color);
  transition: 0.5s;
}

.project-item-three:before {
  z-index: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 767px) {
  .project-item-three img {
    width: 300px;
  }
}

.project-item-three .content {
  position: absolute;
  z-index: 2;
  left: 40px;
  right: 70px;
  bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .project-item-three .content {
    left: 20px;
    bottom: 20px;
  }
}

.project-item-three .content .category {
  color: #fff;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  background-color: #000;
  padding: 8px 15px;
  /* margin-left: 20px; */
  display: inline-block;
  /* opacity: 0; */
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 767px) {
  .project-item-three .content .category {
    font-size: 14px;
  }
}

.project-item-three .content h4 {
  color: var(--heading-color);
  background-color: #1654AF;
  color: #fff;
  padding: 12px 20px;
  /* margin-left: 20px; */
  /* opacity: 0; */
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 767px) {
  .project-item-three .content h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .project-item-three.big-item img {
    width: 452px;
  }
}

.project-item-three:hover:before,
.success-img:hover:before {
  opacity: 0.65;
}

.project-item-three:hover .content .category {
  opacity: 1;
  margin-left: 0;
}

.project-item-three:hover .content h4 {
  opacity: 1;
  margin-left: 0;
  -webkit-transition-delay: .15s;
  -o-transition-delay: .15s;
  transition-delay: .15s;
}

.project-item-three:hover .detail-btn {
  opacity: 1;
  margin-bottom: 0;
}

/* Project Grid */
.project-grid-item {
  /* margin-bottom: 60px; */
  position: relative;
}

.project-grid-item .image {
  position: relative;
}

.project-grid-item .image img {
  width: 100%;
}

.project-grid-item .image:before {
  content: '';
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  mix-blend-mode: multiply;
  background: var(--heading-color);
}

.project-grid-item .image .plus {
  z-index: 2;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 75px;
  width: 100px;
  height: 100px;
}

.project-grid-item .image .plus:after,
.project-grid-item .image .plus:before {
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
}

.project-grid-item .content {
  padding: 20px 30px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  background-color: #fff;
  color: #1654AF;
}

.project-grid-item a {
  text-decoration: none;
}

.project-grid-item .content a {
  text-decoration: none !important;
}

.project-grid-item .content h4 {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .project-grid-item .content h4 {
    font-size: 20px;
  }
}

.project-grid-item .content h5 {
  margin-bottom: 0;
}

.project-grid-item .content .detail-btn {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: 5px;
  color: var(--heading-color);
  width: 45px;
  height: 45px;
  background: var(--lighter-color);
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 375px) {
  .project-grid-item .content .detail-btn {
    display: none;
  }
}

.project-grid-item:hover .image:before {
  opacity: 0.65;
}

.project-grid-item:hover .plus:after {
  width: 100%;
  height: 2px;
}

.project-grid-item:hover .plus:before {
  width: 2px;
  height: 100%;
}

.project-active .project-grid-item {
  margin-bottom: 30px;
}

@media only screen and (min-width: 376px) {
  .project-active .project-grid-item .content {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

/* Project Details */
@media only screen and (min-width: 376px) {
  .project-details-content h4 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 992px) {
  .project-details-content h3 {
    font-size: 35px;
  }
}

.project-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: white;
  position: relative;
  padding: 35px 15px 2px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border: 1px solid var(--border-color);
}

.project-information:after {
  content: '';
  z-index: -1;
  position: absolute;
  left: 10px;
  bottom: -10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 1px solid var(--border-color);
}

.project-information .project-info-item {
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.next-prev-project .next-prev-item .image {
  max-width: 190px;
  position: relative;
}

.next-prev-project .next-prev-item .image img {
  max-width: 190px;
}

.next-prev-project .next-prev-item .image .detail-btn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(27, 31, 46, 0.5);
}

.next-prev-project .next-prev-item .image .detail-btn i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: var(--heading-color);
  background: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .next-prev-project .next-prev-item>div:first-child {
    margin-right: 30px;
  }
}

.next-prev-project .next-prev-item:hover .image .detail-btn {
  opacity: 1;
}

/*******************************************************/
/**************** ## Work Process Area ****************/
/*******************************************************/
.work-process-line {
  margin-bottom: -95px;
}

.work-progress-container {
  max-width: 1630px;
}

@media only screen and (max-width: 575px) {
  .work-process-line {
    margin-bottom: -60px;
  }
}

.work-process-item {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .work-process-item {
    margin-top: 0;
  }
}

.work-process-item .number,
.counter-item .number {
  width: 90px;
  height: 90px;
  color: white;
  font-size: 50px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 700;
  line-height: 60px;
  margin-left: 10px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 30px;
  padding-top: 8px;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  border: 5px solid white;
  font-family: "Poppins", sans-serif;
  background: var(--secondary-color);
}

.work-process-item.mobile {
  text-align: center;
  margin-bottom: 0px;
}

.work-process-item.mobile .content {
  border-radius: 10px;
}

.work-process-item.mobile .number {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  z-index: 2;
}

/* .work-process-item.mobile .content {
  border-radius: 10%;
} */

.work-process-item .content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  background: var(--lighter-color);
  padding: 35px 10px 16px 10px;
  text-align: center;
  border-radius: 10px;
}

.work-process-item .content p,
.work-process-item .content h4 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #1654AF;
}

.work-process-item .content:before {
  position: absolute;
  content: '';
  left: 33px;
  bottom: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-right: 22px solid transparent;
}

.work-process-item:hover .number {
  color: var(--heading-color);
  background: #fff;
  border-color: var(--secondary-color);
}

.work-process-item:hover i {
  color: #1654AF;
}

.work-process-item:hover .content {
  background: var(--secondary-color);
}

.work-process-item:hover .content p,
.work-process-item:hover .content h4 {
  color: white;
}

.work-process-item:hover .content:before {
  border-bottom-color: var(--secondary-color);
}

/* Work Process Two */
.work-process-item-two {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .work-process-item-two {
    margin-top: 0;
  }
}

.work-process-item-two .image {
  margin-right: 50px;
  position: relative;
}

.work-process-item-two .image img {
  width: 100%;
}

.work-process-item-two .image .number {
  width: 55px;
  height: 55px;
  color: white;
  font-size: 18px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  border: 4px solid white;
  font-family: "Poppins", sans-serif;
  background: var(--secondary-color);
  position: absolute;
  right: -27px;
  bottom: -27px;
}

.work-process-item-two .content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 50px;
  padding: 33px 28px 15px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 767px) {
  .work-process-item-two .content {
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.work-process-item-two .content h5 {
  margin-bottom: 10px;
}

@media only screen and (min-width: 376px) {
  .work-process-item-two .content h5 {
    font-size: 20px;
  }
}

.work-process-item-two .content p {
  line-height: 30px;
}

.work-process-item-two:hover .image .number {
  color: var(--heading-color);
  background: var(--primary-color);
}

.work-process-item-two:hover .content {
  background: white;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

.work-process-area-two .work-process-line-two {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  top: 45%;
  z-index: -1;
}

.work-process-area .section-title {
  margin-bottom: 70px;
}

@media (max-width: 576px) {
  .work-process-area .section-title {
    margin-bottom: 0;
  }
}

/*******************************************************/
/****************** ## Team Members *******************/
/*******************************************************/
.team-member {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 15px 40px;
  background: var(--lighter-color);
  position: relative;
  padding-top: 80px;
}

@media only screen and (max-width: 478px) {
  .team-member {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }
}

.team-member img {
  /* margin-top: -100px; */
  border-radius: 50%;
  /* margin-bottom: 30px; */
  position: absolute;
  top: 0;
  left: 50%;
  width: 120px;
  transform: translate(-50%, -50%);
}

@media (max-width: 478px) {
  .team-member img {
    /* margin-top: -50px; */
    /* margin-bottom: 15px; */
  }

  .team-member {
    height: 225px;
  }
}

.team-member h4 {
  margin-bottom: 5px;
}

.team-member .social-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  padding-top: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid var(--border-color);
}

.team-member:not(:hover) .social-style-two a {
  background: white;
}

.team-member:hover {
  background: white;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
}

.team-member.style-two {
  background: white;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
  border-radius: 10px;
}

.team-member.style-two .social-style-two a:not(:hover) {
  background: var(--lighter-color);
}

.team-area-two:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  z-index: -1;
  width: 100%;
  height: 120%;
  background: var(--lighter-color);
}

.team-shapes .shape {
  z-index: -1;
  max-width: 45%;
}

.team-shapes .shape.one {
  position: absolute;
  left: 0;
  bottom: 20%;
}

.team-shapes .shape.two {
  position: absolute;
  right: 0;
  bottom: 5%;
}

.team-page-area {
  background-color: #F0F0F0;
}

/* Team Page */
.team-page-area .team-shapes .shape.one {
  top: -20%;
}

.team-page-area .team-shapes .shape.two {
  top: 70%;
}

@media (max-width: 992px) {
  .team-page-area .section-title {
    margin-bottom: 50px !important;
  }
}

.team-top-video {
  z-index: 1;
  position: relative;
}

.team-top-video::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.3;
  background-color: #1b1f2d;
}

.team-top-video:before {
  z-index: 0;
}

.team-top-video img {
  width: 100%;
}

.team-top-video .video-play {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
  color: var(--secondary-color);
}

.team-top-video .video-play:hover {
  color: white;
  background: var(--secondary-color);
}

/*******************************************************/
/****************** ## Counter Style ******************/
/*******************************************************/
@media only screen and (min-width: 1200px) {

  .statistics-area .container,
  .statistics-area-two .container {
    max-width: 1470px;
  }
}

.statistics-inner {
  margin-top: -140px;
  -webkit-transform: translateY(140px);
  -ms-transform: translateY(140px);
  transform: translateY(140px);
  z-index: 1;
  position: relative;
}

.statistics-inner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 479px) {
  .statistics-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.statistics-inner.style-two {
  margin-top: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.statistics-area-four {
  z-index: 1;
  position: relative;
}

.statistics-area-four::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: var(--heading-color);
}

.statistics-content .read-more {
  font-size: 18px;
}

.counter-text-wrap .count-text {
  display: block;
}

.counter-text-wrap .count-text.plus:after {
  content: '+';
}

.counter-text-wrap .count-text.k-plus:after {
  content: 'k+';
}

.counter-text-wrap .count-text.percent:after {
  content: '%';
}

.counter-item {
  margin-bottom: 50px;
}

.counter-item>i {
  font-size: 55px;
  margin-bottom: 25px;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: white;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  color: var(--secondary-color);
}

/* Counter Style Two */
.counter-item-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 33px 70px 25px;
  border: 1px solid var(--border-color);
}

@media only screen and (max-width: 1399px) {
  .counter-item-two {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-item-two {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .counter-item-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media only screen and (max-width: 375px) {
  .counter-item-two {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.counter-item-two .icon {
  line-height: 1;
  font-size: 65px;
  margin-top: 15px;
  margin-right: 20px;
  color: var(--secondary-color);
}

.counter-item-two .count-text {
  font-size: 65px;
  font-weight: 500;
}

@media only screen and (max-width: 375px) {
  .counter-item-two .count-text {
    font-size: 50px;
  }
}

.counter-item-two .counter-title {
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--heading-color);
}

/* Counter Style Three */
.counter-item-three {
  padding: 50px;
  margin-bottom: 28px;
  background: #23293b;
}

@media only screen and (max-width: 575px) {
  .counter-item-three {
    margin-top: 0;
  }
}

.counter-item-three>i {
  font-size: 55px;
  margin-bottom: 30px;
  display: inline-block;
  color: var(--primary-color);
}

@media only screen and (min-width: 1400px) {
  .statistics-two-content {
    padding-left: 50px;
  }
}

/* Skill Counter */
.circle-progress-item {
  margin-bottom: 30px;
  padding: 40px 30px 20px 40px;
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
}

.circle-progress-item .progress-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
  margin-bottom: 20px;
}

.circle-progress-item .progress-content .h2 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circle-progress-item h4 {
  font-size: 24px;
  margin-bottom: 12px;
}

.skills-area {
  z-index: 1;
  position: relative;
}

.skills-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: var(--lighter-color);
}

.skills-area:before {
  top: -60%;
  height: 120%;
}

@media only screen and (max-width: 991px) {
  .skills-area:before {
    top: -40%;
  }
}

@media only screen and (max-width: 575px) {
  .skills-area:before {
    top: -20%;
  }
}

.skills-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 46vw;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 991px) {
  .skills-bg {
    width: 100%;
    height: 70%;
  }
}

/* Team Circle Progress */
@media only screen and (min-width: 480px) {
  .team-circle-progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.team-circle-progress .circle-progress-counter {
  margin-right: 30px;
  position: relative;
  width: 110px;
}

@media only screen and (max-width: 479px) {
  .team-circle-progress .circle-progress-counter {
    margin-bottom: 10px;
  }
}

.team-circle-progress .circle-progress-counter .h2 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: var(--secondary-color);
}

.team-circle-progress .circle-progress-counter .h2 span {
  font-size: 16px;
}

.team-circle-progress .content p {
  margin-bottom: 5px;
}

/*******************************************************/
/****************** ## Pricing Plan *******************/
/*******************************************************/
.pricing-plan-item {
  background: white;
  position: relative;
  padding: 45px 50px;
  margin-bottom: 30px;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

@media only screen and (max-width: 479px) {
  .pricing-plan-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pricing-plan-item .badge {
  position: absolute;
  top: 0;
  opacity: 0;
  right: 20px;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
  visibility: hidden;
  background: orange;
}

.pricing-plan-item .title {
  font-size: 20px;
  margin-bottom: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pricing-plan-item .price-count {
  display: block;
  margin-bottom: 8px;
  color: var(--secondary-color);
}

@media only screen and (max-width: 991px) {
  .pricing-plan-item .price {
    font-size: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .pricing-plan-item .price {
    font-size: 35px;
  }
}

.pricing-plan-item .price:after {
  content: '€ ';
}

.pricing-plan-item .theme-btn {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 35px;
}

.pricing-plan-item h5 {
  margin-bottom: 20px;
  display: inline-block;
  border-bottom: 1px solid;
}

.pricing-plan-item ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
}

@media only screen and (min-width: 1400px) {
  .pricing-plan-item ul li {
    font-size: 18px;
  }
}

.pricing-plan-item ul li:not(:last-child) {
  margin-bottom: 15px;
}

.pricing-plan-item ul li:before {
  content: "\f0da";
  font-weight: 300;
  margin-right: 10px;
  font-family: 'Font Awesome 5 Pro';
}

.pricing-plan-item ul li a:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

.pricing-plan-item:hover .badge {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pricing-plan-item.style-two {
  padding: 30px 44px 0;
  margin-bottom: 65px;
}

.pricing-plan-item.style-two:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 155px;
  height: 235px;
  /* background: url(../images/background/pricing-bg.png) no-repeat center/cover; */
}

.pricing-plan-item.style-two .title {
  text-align: center;
}

.pricing-plan-item.style-two .price-count {
  text-align: center;
}

.pricing-plan-item.style-two .icon {
  line-height: 1;
  font-size: 96px;
  text-align: center;
  color: var(--secondary-color);
  margin: 25px 0 15px;
}

@media only screen and (max-width: 1399px) {
  .pricing-plan-item.style-two .icon {
    font-size: 65px;
  }
}

.pricing-plan-item.style-two .price {
  margin-top: 22px;
  display: inline-block;
}

@media only screen and (max-width: 1399px) {
  .pricing-plan-item.style-two .price {
    font-size: 30px;
  }
}

.pricing-plan-item.style-two .theme-btn {
  margin-top: 5px;
  margin-bottom: 0;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
}

.pricing-plan-item.style-three .theme-btn {
  margin-bottom: 0;
}

.pricing-plan-item.style-three .price {
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  color: var(--secondary-color);
}

.price-shapes .shape {
  z-index: -1;
  position: absolute;
}

.price-shapes .shape.one {
  left: 0;
  bottom: 7%;
  max-width: 35%;
}

.price-shapes .shape.two {
  top: 0;
  right: 0;
  max-width: 70%;
  -webkit-animation: down-up-one 5s infinite;
  animation: down-up-one 5s infinite;
}

.pricing-wrapper {
  position: relative;
}

.pricing-wrapper:after {
  content: '';
  z-index: -1;
  position: absolute;
  left: -80px;
  bottom: -40px;
  width: calc(100% + 160px);
  height: 90%;
  background: var(--lighter-color);
}

/* Pricing Page */
@media only screen and (min-width: 480px) {
  .price-plan-page-bottom .pricing-plan-item {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.price-plan-page-bottom .pricing-plan-item .price {
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price-plan-page-bottom .pricing-plan-item .price {
    font-size: 33px;
  }
}

.price-plan-page-bottom .pricing-plan-item .theme-btn {
  width: auto;
  padding-top: 9px;
  padding-bottom: 9px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .price-plan-page-bottom .pricing-plan-item .theme-btn {
    font-size: 14px;
    padding-left: 28px;
    padding-right: 28px;
  }
}

.price-page-shapes .shape {
  z-index: -1;
  position: absolute;
}

.price-page-shapes .shape.one {
  left: 0;
  bottom: 0;
  max-width: 70%;
  -webkit-animation: leftRightOne 5s 3s infinite;
  animation: leftRightOne 5s 3s infinite;
}

.price-page-shapes .shape.two {
  bottom: 8%;
  right: 0;
  max-width: 50%;
  -webkit-animation: leftRightOne 5s infinite;
  animation: leftRightOne 5s infinite;
}

/* Pricing Tab */
@media only screen and (max-width: 1199px) {
  .pricing-tab-wrap {
    margin-top: 0;
    margin-bottom: 55px;
  }
}

.pricing-tab {
  padding: 15px 35px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: var(--secondary-color);
}

@media only screen and (max-width: 375px) {
  .pricing-tab {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pricing-tab li:first-child button:after,
.pricing-tab li:last-child button:before {
  content: '';
  width: 22px;
  height: 22px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  border-radius: 50%;
}

.pricing-tab li:first-child button:after {
  margin-left: 20px;
  margin-right: 4px;
}

.pricing-tab li:last-child button:before {
  margin-right: 20px;
  margin-left: 4px;
}

.pricing-tab li:first-child button:before {
  position: absolute;
  width: 50px;
  height: 22px;
  content: '';
  top: 50%;
  right: 0;
  z-index: -1;
  background: white;
  border-radius: 10px;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.pricing-tab li button {
  padding: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fefeff;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background: transparent;
}

@media only screen and (max-width: 375px) {
  .pricing-tab li button {
    font-size: 14px;
  }
}

.pricing-tab li button i {
  margin-left: 10px;
}

.pricing-tab li button:focus,
.pricing-tab li button:hover {
  color: #fefeff;
}

.pricing-tab li:first-child button.active:after,
.pricing-tab li:last-child button.active:before {
  background: var(--primary-color);
}

/*******************************************************/
/*************** ## Testimonials Area *****************/
/*******************************************************/
.testi-image-slider {
  max-width: 400px;
  margin-left: -3px;
  margin-right: -3px;
}

.testi-image-item {
  margin: 3px;
  position: relative;
  padding-bottom: 70px;
}

.testi-image-item img {
  width: 75px;
  height: 75px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 5px solid transparent;
}

.testi-image-item:after {
  position: absolute;
  content: '';
  right: 10px;
  bottom: -30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 15px solid transparent;
  border-right: 22px solid transparent;
}

.testi-image-item:before {
  position: absolute;
  left: 0;
  content: '';
  bottom: 39px;
  width: 80%;
  height: 3px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: transparent;
}

.testi-image-item.slick-current img {
  border-color: white;
  -webkit-box-shadow: 0px 0 10px rgba(103, 77, 243, 0.25);
  box-shadow: 0px 0 10px rgba(103, 77, 243, 0.25);
}

.testi-image-item.slick-current:after {
  bottom: -3px;
  border-bottom-color: var(--lighter-color);
}

.testi-image-item.slick-current:before {
  background: var(--primary-color);
}

.testi-content-slider {
  padding: 30px 40px;
  background: var(--lighter-color);
}

@media only screen and (max-width: 479px) {
  .testi-content-slider {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testi-content-slider:before {
  position: absolute;
  width: 100%;
  height: 1px;
  content: '';
  left: 0;
  top: -44px;
  background: var(--border-color);
}

.testi-content-item p {
  line-height: 1.75;
}

@media only screen and (min-width: 376px) {
  .testi-content-item p {
    font-size: 20px;
  }
}

.testi-content-item .author {
  margin-top: 20px;
  position: relative;
  padding-left: 75px;
}

@media only screen and (max-width: 375px) {
  .testi-content-item .author {
    padding-left: 65px;
  }
}

.testi-content-item .author .h4 {
  display: block;
  margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
  .testi-content-item .author .h4 {
    font-size: 18px;
  }
}

.testi-content-item .author:before {
  position: absolute;
  content: "\f10e";
  font-size: 55px;
  left: 1px;
  top: 50%;
  font-weight: 300;
  color: var(--secondary-color);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
}

@media only screen and (max-width: 375px) {
  .testi-content-item .author:before {
    font-size: 45px;
  }
}

.testimonial-right-part {
  position: relative;
  z-index: 1;
  padding-left: 15px;
  padding-right: 20%;
}

.testimonial-right-part .testi-image-over {
  position: absolute;
  right: 0;
  bottom: 50%;
  max-width: 260px;
  padding: 35px 35px 40px;
  background: var(--primary-color);
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

@media only screen and (max-width: 575px) {
  .testimonial-right-part .testi-image-over {
    padding: 20px 25px 25px;
  }

  .testimonial-right-part .testi-image-over img {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .testimonial-right-part .testi-image-over {
    max-width: 200px;
  }
}

.testimonial-right-part .testi-image-over h3 {
  margin-bottom: 25px;
  letter-spacing: -1px;
}

@media only screen and (max-width: 575px) {
  .testimonial-right-part .testi-image-over h3 {
    font-size: 25px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 375px) {
  .testimonial-right-part .testi-image-over h3 {
    font-size: 20px;
  }
}

.dot-shapes img {
  z-index: -1;
  max-width: 40%;
  position: absolute;
}

.dot-shapes img:first-child {
  top: -40px;
  left: -65px;
  -webkit-animation: leftRightOne 10s infinite;
  animation: leftRightOne 10s infinite;
}

.dot-shapes img:last-child {
  right: -30px;
  bottom: 100px;
  -webkit-animation: upDownLeft 20s infinite;
  animation: upDownLeft 20s infinite;
}

.testimonial-item {
  padding: 40px 60px 30px;
  background: var(--lighter-color);
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-right: 30px;
  margin-bottom: 25px;
}

.testimonial-item .image img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 3px solid white;
}

.testimonial-item .testi-header {
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px; */
  color: #1654AF;
}

.testimonial-item .testi-header h4 {
  margin-right: 15px;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-header h4 {
    font-size: 18px;
  }
}

.testimonial-item .testi-header .ratting {
  margin-bottom: 10px;
}

.testimonial-item .testi-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.testimonial-item .testi-footer .icon {
  line-height: 1;
  font-size: 60px;
  margin-right: 20px;
  color: var(--secondary-color);
}

.testimonial-item .testi-footer img {
  height: 200px;
  width: auto;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-footer .icon {
    font-size: 40px;
    margin-right: 15px;
  }
}

.testimonial-item .testi-footer .title h4 {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .testimonial-item .testi-footer .title h4 {
    font-size: 18px;
  }
}

.testimonial-item.style-two {
  padding: 0 0 30px;
  margin-bottom: 45px;
  background: transparent;
  border-bottom: 1px solid var(--border-color);
}

.testimonial-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-slider .testimonial-item {
  margin-left: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 768px) {
  .testimonial-slider .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 1200px) {
  .testimonial-slider .slick-list {
    padding-right: 500px;
    margin-right: -500px;
  }
}

.testimonial-slider .slick-dots {
  margin-top: 40px;
}

.testimonial-slider .slick-dots li {
  background: var(--secondary-color);
}

.testimonial-slider .slick-dots li.slick-active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  outline: 2px solid var(--primary-color);
}

.testimonial-three-slider .slick-dots {
  margin-top: 50px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

.testimonial-three-slider .slick-dots li {
  background: var(--secondary-color);
}

.testimonial-three-slider .slick-dots li.slick-active {
  background: #fff;
  border-color: var(--primary-color);
  outline: 3px solid var(--primary-color);
}

.testimonial-four-image {
  background-size: cover;
  background-position: center top;
}

.testimonials-three-wrap .slick-dots {
  justify-content: center;
  padding: 0;
}

@media only screen and (min-width: 992px) {
  .testimonial-four-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-four-image {
    width: 100%;
    height: 500px;
  }
}

/*******************************************************/
/******************** ## Blog Area ********************/
/*******************************************************/
.blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-meta li i {
  margin: -3px 8px 0 0;
}

.blog-meta li:not(:last-child):after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: 22px;
  margin-right: 22px;
  background: var(--base-color);
}

.blog-meta-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.blog-meta-two .tag {
  font-weight: 600;
  padding: 1px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid var(--border-color);
}

.blog-meta-two .tag:hover {
  color: var(--heading-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.blog-meta-two .author {
  font-weight: 600;
  color: var(--secondary-color);
  text-transform: uppercase;
}

.blog-meta-two a {
  margin-bottom: 10px;
}

.blog-meta-two a i {
  margin-right: 5px;
}

.blog-meta-two a:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .blog-meta-two a:not(:last-child) {
    margin-right: 15px;
  }
}

.blog-item {
  margin-bottom: 30px;
}

.blog-item .image img {
  width: 100%;
}

.blog-item .content {
  padding: 33px 40px 30px;
}

@media only screen and (max-width: 479px) {
  .blog-item .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-item .content .blog-meta li:after {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: var(--base-color);
}

@media only screen and (max-width: 479px) {
  .blog-item .content .blog-meta li:after {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.blog-item .content h4 {
  margin-bottom: 15px;
  letter-spacing: -1px;
}

@media only screen and (max-width: 375px) {
  .blog-item .content h4 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .blog-item .content h4 {
    font-size: 24px;
  }
}

.blog-item .content .author {
  margin-bottom: 20px;
}

.blog-item .content .author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.blog-item .content .author i {
  margin-left: 10px;
  margin-right: 5px;
}

.blog-item .content .author a {
  font-weight: 500;
  color: var(--secondary-color);
}

.blog-item .content .author-more {
  margin-top: 30px;
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
}

.blog-item .content .author-more .author,
.blog-item .content .author-more .read-more {
  margin-top: 15px;
  margin-bottom: 0;
}

.blog-item.style-two .content {
  padding-top: 0;
}

.blog-item.style-two .blog-meta {
  color: white;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  border-radius: 5px;
  padding: 10px 25px 0;
  margin: -25px -15px 33px;
  background: var(--secondary-color);
}

.blog-item.style-two .blog-meta li a {
  color: white;
}

.blog-item.style-two .blog-meta li:after {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
}

@media only screen and (max-width: 479px) {
  .blog-item.style-two .blog-meta li:after {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.blog-item.style-two:hover .blog-meta {
  color: var(--heading-color);
  background: var(--primary-color);
}

.blog-item.style-two:hover .blog-meta li a {
  color: var(--heading-color);
}

.blog-item.style-two:hover .blog-meta li:after {
  background: var(--heading-color);
}

.blog-item.style-three {
  margin-bottom: 60px;
}

.blog-item.style-three .image,
.blog-item.style-three .content {
  width: 410px;
  max-width: 95%;
}

.blog-item.style-three .content {
  margin-top: -25%;
  margin-left: auto;
  background: white;
  position: relative;
}

.blog-item.style-four {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  padding: 40px 50px;
  border: 1px solid var(--border-color);
}

@media only screen and (min-width: 768px) {
  .blog-item.style-four {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 479px) {
  .blog-item.style-four {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-item.style-four .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 250px;
  margin-right: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-item.style-four .image {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-item.style-four .image {
    margin: 0 0 30px;
  }
}

.blog-item.style-four .content {
  padding: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-item.style-four .content h4 a:hover {
  color: var(--heading-color);
  text-decoration: underline;
}

@media only screen and (max-width: 375px) {
  .blog-item.style-four .content .blog-meta {
    font-size: 15px;
  }

  .blog-item.style-four .content .blog-meta i {
    display: none;
  }
}

.blog-item.style-four .content .author-more {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media only screen and (max-width: 375px) {
  .blog-item.style-four .content .author-more {
    font-size: 15px;
  }
}

.blog-item.style-four .content .author-more .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-item.style-four .content .author-more .author:after {
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 50%;
  margin-left: 40px;
  margin-right: 40px;
  background: var(--secondary-color);
}

@media only screen and (max-width: 375px) {
  .blog-item.style-four .content .author-more .author:after {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.blog-item.style-four:hover {
  border-color: white;
  -webkit-box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 10px 0 60px rgba(103, 77, 243, 0.1);
}

/* Blog Standard */
.blog-standard-item {
  margin-bottom: 50px;
}

@media only screen and (min-width: 1200px) {
  .blog-standard-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.blog-standard-item .image {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) {
  .blog-standard-item .image {
    width: 48%;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-bottom: 0;
    margin-right: 50px;
  }
}

.blog-standard-item .content h4 {
  line-height: 1.48;
  margin-bottom: 18px;
}

@media only screen and (min-width: 376px) {
  .blog-standard-item .content h4 {
    font-size: 24px;
  }
}

.blog-standard-item .content p {
  margin-bottom: 20px;
}

/* Blog Details */
.blog-details-content h3 {
  line-height: 1.4;
}

@media only screen and (max-width: 575px) {
  .blog-details-content h3 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-details-content h3 {
    font-size: 25px;
  }
}

.blog-details-content>h4 {
  margin-bottom: 15px;
}

@media only screen and (min-width: 376px) {
  .blog-details-content>h4 {
    font-size: 24px;
  }
}

.blog-details-content p {
  margin-bottom: 15px;
}

.tag-share {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tag-share .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.tag-share .item h5 {
  margin: 0 20px 0 0;
}

.tag-share .item .tag-coulds a {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  text-transform: capitalize;
}

blockquote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 33px 50px 30px;
  border: 1px solid var(--border-color);
}

@media only screen and (max-width: 575px) {
  blockquote {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 375px) {
  blockquote {
    padding-left: 15px;
    padding-right: 15px;
  }
}

blockquote:before {
  content: "";
  line-height: 1;
  font-size: 45px;
  margin-right: 25px;
  font-family: "Flaticon";
  color: var(--secondary-color);
}

@media only screen and (max-width: 479px) {
  blockquote:before {
    font-size: 35px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  blockquote h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 375px) {
  blockquote h4 {
    font-size: 16px;
  }
}

blockquote .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 375px) {
  blockquote .name {
    font-size: 14px;
  }
}

blockquote .name:before {
  content: '';
  width: 40px;
  height: 3px;
  margin-right: 20px;
  background: var(--primary-color);
}

@media only screen and (max-width: 375px) {
  blockquote .name:before {
    width: 20px;
    margin-right: 15px;
  }
}

/* Comments */
@media only screen and (min-width: 376px) {
  .comment-title {
    font-size: 24px;
  }
}

.comment-body {
  padding-top: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.comment-body:first-child {
  border-top: 1px solid var(--border-color);
}

@media only screen and (min-width: 480px) {
  .comment-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.comment-body .author-thumb {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 85px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.comment-body .author-thumb img {
  border-radius: 50%;
}

.comment-body .content h5 {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comment-body .content h5 .theme-btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 18px;
  font-family: "Poppins", sans-serif;
}

.comment-body .content .date {
  display: block;
  margin: -2px 0 8px;
}

.admin-comment .comment-body {
  border: none;
  padding: 35px 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--lighter-color);
}

@media only screen and (max-width: 575px) {
  .admin-comment .comment-body {
    display: block;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.admin-comment .comment-body .author-thumb {
  max-width: 140px;
}

@media only screen and (min-width: 576px) {
  .admin-comment .comment-body .author-thumb {
    margin-bottom: 0;
  }
}

/* Next Prev Post */
.next-prev-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.next-prev-post .post-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 340px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.next-prev-post .post-item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 90px;
  margin-right: 30px;
}

.next-prev-post .post-item h5 {
  line-height: 1.65;
  margin-bottom: 5px;
}

.next-prev-post .post-item h5 a {
  color: var(--heading-color);
}

.next-prev-post .post-item h5 a:hover {
  text-decoration: underline;
}

.next-prev-post .post-item .date i {
  margin-right: 5px;
}

/* Comment Form */
.comment-form {
  padding: 50px 50px 60px;
}

@media only screen and (max-width: 375px) {
  .comment-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (min-width: 376px) {
  .comment-form h4 {
    font-size: 24px;
  }
}

.comment-form .form-group label {
  position: absolute;
  top: 0;
  right: 0;
}

.comment-form .form-control {
  font-size: 18px;
  border-radius: 0;
  padding: 15px 0 12px;
  border-width: 0 0 1px;
  background-color: transparent;
  border-color: var(--border-color);
}

.comment-form .form-control::-webkit-input-placeholder {
  color: var(--heading-color);
}

.comment-form .form-control:-ms-input-placeholder {
  color: var(--heading-color);
}

.comment-form .form-control::-ms-input-placeholder {
  color: var(--heading-color);
}

.comment-form .form-control::placeholder {
  color: var(--heading-color);
}

.comment-form .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.comment-form .form-check .form-check-input[type=radio] {
  width: 24px;
  height: 24px;
  padding: 0;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  margin-right: 20px;
  border-radius: 5px;
  background: transparent;
  border: 2px solid rgba(32, 78, 207, 0.2);
}

.comment-form .form-check .form-check-input[type=radio]:after {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: var(--border-color);
}

.comment-form .form-check .form-check-input[type=radio]:checked:after {
  background: var(--secondary-color);
}

.comment-form .form-check label {
  font-weight: 400;
  position: relative;
}

/*******************************************************/
/****************** ## Contact Forms ******************/
/*******************************************************/
.form-style-one .form-group {
  margin-bottom: 25px;
}

.form-style-one .form-control {
  font-size: 18px;
  font-weight: 500;
  border-radius: 0;
  padding: 10px 0 15px;
  border-width: 0 0 1px;
}

.form-style-one .form-control:focus {
  border-color: var(--heading-color);
}

.form-style-two .form-group {
  margin-bottom: 30px;
}

.form-style-two .form-control {
  border: none;
  font-size: 18px;
  border-radius: 0;
  font-weight: 500;
}

@media only screen and (min-width: 576px) {
  .form-style-two .form-control {
    padding: 22px 35px;
  }
}

.contact-form {
  border-radius: 10px;
}

.contact-form-area {
  /* background-color: rgba(22, 84, 175, 0.8); */
  /* background-image: url('./contact-form-bg.jpg') !important; */
}

.contact-form {
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.1);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.1);
}

@media only screen and (max-width: 479px) {
  .contact-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* .contact-info-wrap {
  margin-left: 25px;
}

.contact-info-wrap h2 {
  color: var(--heading-color);
}

.contact-info-wrap .sub-title {
  color: #fff;
} */

.contact-info-part {
  /* max-width: 415px; */
  padding: 30px;
  background: var(--secondary-color);
  color: #fff;
  border-radius: 10px;
}

@media only screen and (max-width: 479px) {
  .contact-info-part {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .contact-info-item .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: white;
  font-size: 22px;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background: var(--secondary-color);
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
} */

.contact-info-item h5 {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .contact-info-item h5 {
    font-size: 16px;
  }
}

.contact-info-item:not(:last-child) {
  margin-bottom: 20px;
}

/* Contact Info Box */
.contact-info-box {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  margin-bottom: 30px;
  padding: 20px 40px;
  border: 1px solid var(--border-color);
}

.contact-info-box a {
  text-decoration: none;
  color: var(--bs-heading-color);
}

.contact-info-box a:hover {
  color: var(--secondary-color);
}

@media only screen and (min-width: 376px) {
  .contact-info-box {
    font-size: 18px;
  }
}

.contact-info-box .icon {
  font-size: 40px;
  /* margin-bottom: 10px; */
  padding-right: 40px;
  color: var(--secondary-color);
}

@media only screen and (max-width: 375px) {
  .contact-info-box .icon {
    font-size: 40px;
  }
}

@media only screen and (min-width: 376px) {
  .contact-info-box h4 {
    font-size: 24px;
  }
}

.contact-info-box>a,
.contact-info-box>span {
  display: inline-block;
}

.contact-info-box>b {
  font-weight: 500;
  color: var(--secondary-color);
}

.contact-info-box:hover {
  border-color: white;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.2);
}

/* Location Map */
.our-location iframe {
  height: 500px;
}

@media only screen and (max-width: 1399px) {
  .our-location iframe {
    height: 600px;
  }
}

@media only screen and (max-width: 991px) {
  .our-location iframe {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .our-location iframe {
    height: 400px;
  }
}

/* Contact Page Form */
.contact-form-wrap {
  /* margin-top: -165px; */
  padding: 130px 110px;
}

@media only screen and (max-width: 991px) {
  .contact-form-wrap {
    margin-top: -100px;
    padding: 100px 50px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-form-wrap {
    padding: 60px 25px;
  }
}

/* Contact Form Validation */
.has-error .with-errors {
  color: red;
  margin-top: 5px;
  margin-bottom: -15px;
}

#msgSubmit {
  margin-bottom: 0;
  margin-top: 10px;
}

/*******************************************************/
/******************* ## FAQs Area *********************/
/*******************************************************/
.accordion-item {
  z-index: 1;
  border: none;
  padding: 20px 25px;
  position: relative;
  border-radius: 0 !important;
  -webkit-box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
  box-shadow: 10px 0 60px rgba(102, 83, 232, 0.15);
}

@media only screen and (max-width: 375px) {
  .accordion-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.accordion-item:not(:last-child) {
  margin-bottom: 15px;
}

.accordion-item .accordion-button {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 700;
  position: inherit;
  color: var(--heading-color);
  background: transparent;
}

@media only screen and (min-width: 376px) {
  .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.accordion-item .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item .accordion-body {
  padding: 0;
}

.accordion-item .accordion-body p {
  margin: 10px 0 0;
}

.style-two .accordion-item {
  z-index: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  background: transparent;
}

.style-two .accordion-button:after {
  content: '\f06e';
  font-weight: 400;
  background-image: none;
  color: var(--secondary-color);
  font-family: 'Font Awesome 5 Pro';
}

.style-two .accordion-button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid transparent;
  -webkit-box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
  box-shadow: 3px 0 60px rgba(103, 77, 243, 0.1);
}

.style-two .accordion-button.collapsed:before {
  border-color: var(--border-color);
}

.style-two .accordion-button.collapsed:after {
  content: '\f070';
  color: var(--heading-color);
}

.style-three .accordion-button {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .style-three .accordion-button {
    font-size: 16px;
  }
}

.style-three .accordion-button:after {
  content: '\f06e';
  font-weight: 400;
  background-image: none;
  color: var(--secondary-color);
  font-family: 'Font Awesome 5 Pro';
}

.style-three .accordion-button.collapsed:after {
  content: '\f070';
  color: var(--heading-color);
}

/* Team Page */
.faq-images {
  position: relative;
  padding-left: 100px;
}

@media only screen and (max-width: 479px) {
  .faq-images {
    padding-left: 50px;
  }
}

.faq-images .logo {
  position: absolute;
  left: 0;
  top: 50%;
  padding: 40px 45px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: var(--secondary-color);
  -webkit-clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
}

@media only screen and (max-width: 479px) {
  .faq-images .logo {
    max-width: 150px;
    padding: 20px 25px;
  }
}

@media only screen and (max-width: 375px) {
  .faq-images .logo {
    max-width: 100px;
  }
}

/*******************************************************/
/*********************** ## Shop ***********************/
/*******************************************************/
.product-item {
  text-align: center;
  margin-bottom: 55px;
}

.product-item .image {
  position: relative;
  margin-bottom: 28px;
}

.product-item .image .social-style-two {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  margin-top: -20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}

.product-item .image .social-style-two a {
  color: white;
  background: var(--secondary-color);
}

.product-item .image .social-style-two a:hover {
  color: var(--heading-color);
  background: var(--primary-color);
}

@media only screen and (min-width: 376px) {
  .product-item .content h5 {
    font-size: 20px;
  }
}

.product-item .content .price {
  font-size: 18px;
  font-weight: 500;
}

.product-item .content .price:before {
  content: '$';
}

.product-item:hover .social-style-two {
  opacity: 1;
  margin-top: 0;
}

.shop-shorter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-shorter .filter-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.shop-shorter .filter-part i {
  font-size: 30px;
  margin-right: 20px;
  color: var(--secondary-color);
}

.shop-shorter .filter-part span {
  font-size: 20px;
}

.shop-shorter .sort-text {
  font-size: 18px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.shop-shorter .products-dropdown {
  margin-right: 30px;
  margin-bottom: 15px;
}

.shop-shorter .products-dropdown .nice-select {
  border: none;
  font-size: 16px;
  background: var(--lighter-color);
  padding: 15px 80px 15px 30px;
}

.shop-shorter .grid-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.shop-shorter .grid-list li a {
  font-size: 30px;
}

.shop-shorter .grid-list li a:hover {
  color: var(--secondary-color);
}

.shop-shorter .grid-list li:not(:last-child) {
  margin-right: 30px;
}

/* Product Details */
.preview-images {
  margin-bottom: 30px;
}

.preview-images img {
  width: 100%;
}

.thumb-images {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.thumb-images .thumb-item {
  width: calc(33.3333% - 20px);
}

.thumb-images .thumb-item img {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .section-title h2 {
    font-size: 40px;
  }
}

.product-details-content .ratting-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-content .ratting-price .price {
  font-size: 24px;
  font-weight: 500;
  color: var(--heading-color);
  margin-left: 10px;
}

.product-details-content .ratting-price .price:before {
  content: '$';
}

.product-details-content .category-tags li:not(:last-child) {
  margin-bottom: 8px;
}

.product-details-content .category-tags li b {
  font-size: 18px;
  color: var(--heading-color);
}

.product-details-content .category-tags li span {
  margin-left: 20px;
  margin-right: 20px;
}

.product-details-content .category-tags li a:not(:last-child):after {
  content: ',';
  margin-right: 3px;
}

.product-details-content .add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details-content .add-to-cart input {
  width: 88px;
  padding: 8px 20px;
  margin: 10px 10px 0 0;
}

.product-details-content .add-to-cart .theme-btn {
  padding-top: 9px;
  padding-bottom: 9px;
  margin: 10px 10px 0 0;
}

.product-details-content .add-to-cart .wishlist {
  margin-top: 10px;
  padding: 8px 20px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid var(--border-color);
}

.product-information-tab li:not(:last-child) {
  margin-right: 15px;
}

.product-information-tab li a {
  font-size: 18px;
  font-weight: 700;
  padding: 11px 65px;
  border-radius: 5px;
  display: inline-block;
  color: var(--heading-color);
  border: 1px solid var(--border-color);
}

@media only screen and (max-width: 575px) {
  .product-information-tab li a {
    padding: 6px 30px;
  }
}

@media only screen and (max-width: 375px) {
  .product-information-tab li a {
    font-size: 16px;
    padding: 5px 20px;
  }
}

.product-information-tab li a.active {
  color: white;
  background: var(--secondary-color);
}

.review-form .ratting b {
  font-size: 20px;
  font-weight: 400;
  margin-right: 20px;
}

.review-form .form-group {
  margin-bottom: 30px;
}

.review-form .form-group .nice-select,
.review-form .form-group .form-control {
  line-height: 1.4;
  background: var(--lighter-color);
  border-color: var(--lighter-color);
}

@media only screen and (min-width: 480px) {

  .review-form .form-group .nice-select,
  .review-form .form-group .form-control {
    font-size: 18px;
  }
}

.review-form .form-group .nice-select::-webkit-input-placeholder,
.review-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select:-ms-input-placeholder,
.review-form .form-group .form-control:-ms-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select::-ms-input-placeholder,
.review-form .form-group .form-control::-ms-input-placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select::placeholder,
.review-form .form-group .form-control::placeholder {
  color: var(--heading-color);
}

.review-form .form-group .nice-select:focus,
.review-form .form-group .form-control:focus {
  border-color: var(--heading-color);
}

.review-form .form-group .nice-select .current,
.review-form .form-group .form-control .current {
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .review-form .theme-btn {
    padding: 14px 55px;
  }
}

.related-product-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.related-product-slider .product-item {
  margin-left: 15px;
  margin-right: 15px;
}

.related-product-slider .product-item img {
  display: inline-block;
}

.related-product-slider .slick-dots {
  margin-top: 10px;
}

/*******************************************************/
/****************** ## Cart Checkout *******************/
/*******************************************************/
/* Cart Page */
.shoping-table table,
.shoping-cart-total table {
  width: 100%;
}

.shoping-table td,
.shoping-table th,
.shoping-cart-total td,
.shoping-cart-total th {
  padding: 15px 10px;
  text-align: center;
  border: 1px solid var(--border-color);
}

.shoping-table th,
.shoping-cart-total th {
  text-transform: capitalize;
}

.shoping-table td img,
.shoping-cart-total td img {
  max-height: 60px;
}

.shoping-table td .title,
.shoping-cart-total td .title {
  font-size: 18px;
  color: var(--heading-color);
}

.shoping-table td .price:before,
.shoping-cart-total td .price:before {
  content: '$';
}

.shoping-table td button,
.shoping-cart-total td button {
  background: transparent;
}

@media only screen and (max-width: 767px) {
  .shoping-table thead {
    display: none;
  }

  .shoping-table tr {
    display: -ms-grid;
    display: grid;
  }

  .shoping-table tr:not(:last-child) {
    margin-bottom: 50px;
  }

  .shoping-table td:not(:last-child) {
    border-bottom: none;
  }
}

.quantity-input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.quantity-input button {
  width: 45px;
  background: transparent;
  border: 1px solid var(--border-color);
}

.quantity-input input {
  width: 55px;
  padding: 5px 15px;
  text-align: center;
  background: transparent;
}

.discount-wrapper input {
  width: 200px;
}

/* Checkout Page */
.checkout-faqs .alert {
  padding: 20px 30px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 375px) {
  .checkout-faqs .alert {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.checkout-faqs .alert h6 {
  margin-bottom: 0;
}

.checkout-faqs .alert h6 a {
  padding: 0;
  color: var(--secondary-color);
  background: transparent;
}

.checkout-faqs .alert form {
  padding: 20px 0 10px;
}

.checkout-faqs .alert form .form-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.checkout-faqs .alert form .form-footer button {
  margin-right: 15px;
}

@media only screen and (max-width: 375px) {
  .checkout-faqs .alert form .form-footer button {
    margin-right: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.checkout-faqs .alert form .form-footer label {
  line-height: 1.4;
  margin: 0 0 0 5px;
}

.checkout-faqs .checkout-form h5 {
  margin-bottom: 20px;
}

/*******************************************************/
/****************** ## Videos Area ********************/
/*******************************************************/
/* Video Play */
.video-play {
  color: white;
  display: inline-block;
  width: 80px;
  height: 80px;
  background: -webkit-linear-gradient(120deg, #30f0b6 0%, #674df3 100%);
  background: -o-linear-gradient(120deg, #30f0b6 0%, #674df3 100%);
  background: linear-gradient(-30deg, #30f0b6 0%, #674df3 100%);
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
}

.video-play:hover {
  color: white;
}

@media only screen and (max-width: 575px) {
  .video-play {
    width: 60px;
    height: 60px;
    font-size: 16px;
    line-height: 60px;
  }
}

.video-play.style-two {
  border-radius: 0;
}

/* Video Play With Text */
.video-play-text {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.video-play-text i {
  color: white;
  font-size: 14px;
  margin-right: 15px;
  width: 55px;
  height: 55px;
  background: var(--secondary-color);
  line-height: 55px;
  border-radius: 50%;
  text-align: center;
}

.video-play-text span {
  font-weight: 500;
  color: var(--heading-color);
  text-decoration: underline;
}

/* Video Area Two */
.video-area-two:before {
  content: '';
  width: 100%;
  height: 40%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: var(--lighter-color);
}

.video-wrap-two {
  z-index: 1;
  position: relative;
}

.video-wrap-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.3;
  background-color: var(--heading-color);
}

.video-wrap-two:before {
  z-index: 2;
}

.video-wrap-two .video-play {
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  width: 150px;
  height: 150px;
  background: white;
  line-height: 150px;
  border-radius: 50%;
  text-align: center;
  color: var(--secondary-color);
}

@media only screen and (max-width: 991px) {
  .video-wrap-two .video-play {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .video-wrap-two .video-play {
    width: 70px;
    height: 70px;
    font-size: 16px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 375px) {
  .video-wrap-two .video-play {
    width: 50px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
  }
}

/*******************************************************/
/***************** ## Sidebar Widgets *****************/
/*******************************************************/
.widget:not(:last-child) {
  margin-bottom: 50px;
}

.widget-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

@media only screen and (min-width: 1200px) {
  .widget-title {
    font-size: 24px;
  }
}

.widget-search .widget-title {
  margin-bottom: 0;
  border-bottom: none;
}

.widget-search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  padding: 14px 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--lighter-color);
}

.widget-search form input {
  padding: 0;
  border: none;
  font-size: 16px;
  background: transparent;
}

.widget-search form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: transparent;
}

.widget-category ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-category ul li:not(:last-child) {
  margin-bottom: 15px;
}

.widget-category ul li:before {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  content: "\f101";
  margin-right: 10px;
  font-family: "Font Awesome 5 Pro";
}

.widget-category ul li a {
  margin-right: auto;
}

.widget-category ul li a:not(:hover) {
  color: var(--heading-color);
}

.widget-recent-news ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.widget-recent-news ul li:not(:last-child) {
  margin-bottom: 30px;
}

.widget-recent-news ul li .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 90px;
  margin-right: 30px;
}

@media only screen and (max-width: 375px) {
  .widget-recent-news ul li .image {
    max-width: 70px;
    margin-right: 15px;
  }
}

.widget-recent-news ul li h5 {
  margin-bottom: 0;
  line-height: 1.65;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-recent-news ul li h5 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .widget-recent-news ul li h5 {
    font-size: 15px;
    line-height: 1.3;
  }
}

.widget-cta {
  z-index: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 40px 0 15px;
  background: var(--secondary-color);
}

.widget-cta h4 {
  color: white;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media only screen and (min-width: 376px) {
  .widget-cta h4 {
    font-size: 27px;
  }
}

.widget-cta .theme-btn.style-two {
  border-radius: 5px;
  padding: 7px 25px;
  color: var(--heading-color);
}

.widget-cta .theme-btn.style-two:after {
  background: var(--primary-color);
}

.widget-cta .cta-bg-line {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 30%;
  -webkit-animation: down-up-one 15s infinite;
  animation: down-up-one 15s infinite;
}

.widget-cta .cta-bg-dots {
  position: absolute;
  z-index: -1;
  right: 5%;
  top: 35%;
  max-width: 45%;
  -webkit-animation: down-up-two 15s infinite;
  animation: down-up-two 15s infinite;
}

.tag-coulds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.tag-coulds a {
  font-size: 14px;
  font-weight: 500;
  padding: 2px 16px;
  margin: 10px 5px 0;
  color: var(--heading-color);
  text-transform: uppercase;
  background: var(--lighter-color);
}

.tag-coulds a:hover {
  background: var(--primary-color);
}

/* Service Sidebar */
.service-sidebar .widget {
  padding: 40px;
  background: var(--lighter-color);
}

.service-sidebar a {
  text-decoration: none;
}

@media only screen and (max-width: 375px) {
  .service-sidebar .widget {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-sidebar .widget:not(:last-child) {
  margin-bottom: 30px;
}

.service-sidebar .widget-title {
  border-bottom: none;
  margin-bottom: 10px;
}

.service-sidebar .widget-category li:not(:last-child) {
  margin-bottom: 10px;
}

.service-sidebar .widget-category li a {
  width: 100%;
  font-weight: 600;
  background: white;
  padding: 11px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px) {
  .service-sidebar .widget-category li a {
    font-size: 18px;
  }
}

.service-sidebar .widget-category li a:hover {
  color: white;
  background: var(--secondary-color);
}

.service-sidebar .widget-category li:before {
  display: none;
}

.service-sidebar .widget-cta {
  padding-top: 85px;
  padding-bottom: 85px;
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: relative;
}

.service-sidebar .widget-cta::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: var(--heading-color);
}

.service-sidebar .widget-cta:before {
  mix-blend-mode: multiply;
}

.service-sidebar .widget-cta .h5 {
  color: white;
  margin-bottom: 12px;
  display: inline-block;
}

.service-sidebar .widget-cta h2 {
  color: white;
  line-height: 1;
  font-size: 55px;
  margin-bottom: 45px;
}

@media only screen and (max-width: 1199px) {
  .service-sidebar .widget-cta h2 {
    font-size: 40px;
    margin-top: 10px;
  }
}

.service-sidebar .widget-cta .theme-btn {
  color: white;
  margin-bottom: 20px;
  border-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.service-sidebar .widget-cta .number {
  color: white;
  font-weight: 500;
}

.service-sidebar .widget-cta .number i {
  font-size: 22px;
  margin-right: 5px;
  color: var(--primary-color);
}

.service-sidebar .widget-cta .bg-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  max-width: 95%;
}

.service-sidebar .widget-download li:not(:last-child) {
  margin-bottom: 10px;
}

.service-sidebar .widget-download li a {
  font-weight: 600;
  background: white;
  padding: 11px 30px;
  color: var(--heading-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px) {
  .service-sidebar .widget-download li a {
    font-size: 18px;
  }
}

.service-sidebar .widget-download li a i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding-left: 20px;
  border-left: 2px solid var(--border-color);
}

.service-sidebar .widget-download li a:hover {
  color: white;
  background: var(--secondary-color);
}

.service-sidebar .widget-download li a:hover i {
  border-color: white;
}

/* Shop Sidebar */
.shop-sidebar .widget-title {
  font-size: 22px;
}

.shop-sidebar .widget-search form {
  background: #f4f2fe;
}

.shop-sidebar .widget-category ul li {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.shop-sidebar .widget-category ul li span {
  color: var(--heading-color);
}

.shop-sidebar .widget-category ul li:before {
  width: 5px;
  height: 5px;
  content: '';
  opacity: 0.3;
  margin-right: 15px;
  border-radius: 50%;
  background: var(--secondary-color);
}

.shop-sidebar .widget-products ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-sidebar .widget-products ul li:not(:last-child) {
  margin-bottom: 30px;
}

.shop-sidebar .widget-products ul li .image {
  max-width: 110px;
  margin-right: 22px;
}

.shop-sidebar .widget-products ul li .content .ratting i {
  color: #ff7800;
  font-size: 13px;
}

.shop-sidebar .widget-products ul li .content h5 {
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-sidebar .widget-products ul li .content h5 {
    font-size: 16px;
  }
}

/*******************************************************/
/******************* ## Main Footer *******************/
/*******************************************************/
@media only screen and (min-width: 1200px) {
  .footer-cta-wrap .container {
    max-width: 1470px;
  }
}

.footer-cta-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 60px 80px 35px;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
  z-index: 1;
  position: relative;
  border-radius: 10px;
  margin: 0 1px !important;
}

.footer-cta-inner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: var(--primary-color);
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .footer-cta-inner {
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .footer-cta-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.footer-cta-inner .section-title {
  margin-bottom: 25px;
  margin-right: auto;
}

.footer-cta-inner .section-title .sub-title {
  margin-bottom: 8px;
  color: var(--heading-color);
}

@media only screen and (max-width: 375px) {
  .footer-cta-inner .section-title .sub-title {
    font-size: 18px;
  }
}

.footer-cta-inner .section-title h2 {
  margin-bottom: 0;
  letter-spacing: -1px;
}

.footer-cta-inner .theme-btn {
  margin-bottom: 25px;
  margin-right: 30px;
}

.footer-cta-inner .hotline {
  margin-bottom: 25px;
}

.footer-cta-inner .hotline>i {
  background: white;
  border-color: white;
}

.footer-cta-inner .hotline .content span,
.footer-cta-inner .hotline .content a {
  color: var(--heading-color);
}

.footer-logo {
  width: 200px;
  display: inline-block;
  -webkit-clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
}

.footer-white {
  color: #b6bace;
}

.footer-white a:not(:hover) {
  color: #b6bace;
}

.footer-white .read-more {
  color: var(--primary-color);
}

.footer-white h5,
.footer-white .footer-title,
.footer-white .footer-bottom-menu li a:hover {
  color: white;
}

.footer-white .widget_newsletter form {
  border-color: rgba(255, 255, 255, 0.3);
}

.footer-widget {
  margin-bottom: 10px;
}

.footer-title {
  margin-bottom: 28px;
}

@media only screen and (min-width: 376px) {
  .footer-title {
    font-size: 24px;
  }
}

.widget_newsletter form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 2px solid var(--border-color);
}

.widget_newsletter form label {
  margin: 5px 6px 0 0;
  color: var(--primary-color);
}

.widget_newsletter form input {
  padding: 0;
  border: none;
  max-width: 150px;
  margin-right: auto;
  background: transparent;
}

.widget_newsletter form button {
  color: white;
  line-height: 1.5;
  font-weight: 500;
  padding: 5px 12px;
  background: var(--secondary-color);
}

.widget_newsletter .social-style-one a {
  margin-right: 15px;
}

/* Footer Bottom */
.footer-bottom-menu ul {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: -10px;
}

.footer-bottom-menu ul li {
  margin: 0 10px 5px 0;
}

.footer-bottom-menu ul li a {
  color: var(--heading-color);
}

.footer-bottom-menu ul li a:hover {
  text-decoration: underline;
}

.footer-bottom-menu ul li:not(:last-child):after {
  content: '|';
  margin-left: 10px;
}

.footer-shapes .shape {
  position: absolute;
  z-index: -2;
  max-width: 50%;
}

.footer-shapes .shape.one {
  left: 0;
  bottom: 0;
}

.footer-shapes .shape.two {
  right: 0;
  bottom: 0;
}

.footer-shapes .shape.three {
  right: 0;
  bottom: 10%;
  z-index: -1;
}

.navbar-collapse .navigation .dropdown a,
.theme-btn {
  text-decoration: none;
}

.header-inner1 {
  margin: 0px 50px 0px 50px;
}

.footer-conternt {
  font-weight: 600;
  color: var(--heading-color);
  font-family: "Poppins", sans-serif;
  font-size: 28px;
}

@media (max-width: 768px) {
  .footer-conternt {
    font-size: 22px;
    margin-top: 10px;
  }
}

.project-area-four {
  background-color: rgba(22, 84, 175, 0.8);
  padding: 50px 0;
  color: #fff;
  overflow: hidden;
}

.project-area-four .slick-track {
  display: flex;
}

.project-three-active .slick-arrow.prev-arrow {
  left: 2%;
}

.project-three-active .slick-arrow {
  right: 2%;
}

.navigation {
  margin-bottom: 0px;
}

.container-nav-outer {
  display: flex;
  justify-content: space-between;
}

.nav-outer {
  margin-right: 30px;
}

.menu-btns .theme-btn {
  padding-top: 20px;
  padding-bottom: 20px;
}

.container-fluid {
  background-color: #ffffff;
  /* box-shadow: 0px 0px 30px 0px rgba(46, 3, 237, 0.1) */
}

.contact-info-box {
  height: 100%;
}

.col-xl-6 {
  padding: 10px;
}

.sub-title1 {
  color: white;
}

.footer-conternt {
  color: white;
}

.main-footer {
  background-image: url('./map.png');
  background-size: cover;
  background-color: #1B2D58;
}

.technology-section {
  background-color: #F0F0F0;
  padding: 30px 0;
}

.services-area {
  background: #1B2D58;
}

.services-area a {
  text-decoration: none;
  color: var(--heading-color);
}

.services-area .container,
.work-process-area .container {
  max-width: 1620px;
}

.services-area .service-two-item a {
  text-decoration: none;
}

.about-us-section {
  padding: 70px 0;
}

.about-us-section .choose-top .choose-title {
  padding-top: 80px;
  max-width: 450px;
  width: 100%;
  margin-left: 50px;
  color: var(--heading-color);
}

@media (max-width: 991px) {
  .about-us-section .choose-top .choose-title {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .about-us-section {
    padding: 40px 0;
  }

  .about-us-section .choose-top .choose-title {
    margin-left: 0;
  }
}

.about-us-section .choose-top .choose-title span {
  font-family: var(--font-saira);
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  color: var(--theme-color);
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}

.about-us-section .choose-top .choose-title span::after {
  content: "";
  height: 1px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--heading-color);
}

.about-us-section .choose-top .choose-title h2 {
  font-family: var(--font-saira);
  font-weight: 700;
  font-size: 45px;
  line-height: 1.3;
  color: var(--heading-color);
}

@media (max-width: 1199px) {
  .about-us-section .choose-top .choose-title h2 {
    font-size: 35px !important;
  }
}

@media (max-width: 576px) {
  .about-us-section .choose-top .choose-title h2 {
    font-size: 30px !important;
  }
}

.about-us-section .choose-top .choose-right-img {
  position: relative;
  /* z-index: 1; */
  box-shadow: 3px 0 60px rgba(103, 103, 103, 0.6);
  border-radius: 10px;
}

.about-us-section .choose-top .choose-right-img img {
  border-radius: 10px;
}

@media (max-width: 991px) {
  .about-us-section .choose-top .choose-right-img img {
    width: 100%;
  }
}

.about-us-section .choose-btm {
  padding-top: 50px;
}

.about-us-section .choose-btm .choose-left-content {
  /* background-image: url(../img/home-6/choose-bg-1.png), linear-gradient(#1e1c22, #1e1c22); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  background-color: #1971D1;
  padding: 65px 75px 65px 85px;
  position: relative;
  margin-right: 15px;
  margin-top: -120px;
  box-shadow: 3px 0 60px rgba(103, 103, 103, 0.6);
  border-radius: 10px;
}

@media (max-width: 1199px) {
  .about-us-section .choose-btm .choose-left-content {
    margin-top: 0;
    padding: 65px 30px 65px 30px;
  }
}

@media (max-width: 991px) {
  .about-us-section .choose-btm .choose-left-content {
    margin-right: 0;
  }
}

.about-us-section .choose-btm .choose-left-content .icon {
  margin-bottom: 22px;
}

.about-us-section .choose-btm .choose-left-content h4 {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 24px;
  line-height: 1.46;
  letter-spacing: 0.03em;
  color: #E4E4E4;
  margin-bottom: 25px;
}

.about-us-section .choose-btm .choose-left-content h4 span {
  color: var(--theme-color);
  font-size: 26px;
  font-weight: 600;
}

@media (max-width: 576px) {
  .about-us-section .choose-btm .choose-left-content h4 {
    font-size: 22px;
  }

  .about-us-section .choose-btm .choose-left-content h4 span {
    font-size: 24px;
  }
}

.about-us-section .choose-btm .choose-left-content p {
  font-family: var(--font-saira);
  font-weight: 300;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #fff;
  /* padding-left: 60px; */
}

@media (max-width: 1199px) {
  .about-us-section .choose-btm .choose-left-content p {
    /* padding-left: 30px; */
  }
}

@media (max-width: 576px) {
  .about-us-section .choose-btm .choose-left-content p {
    /* padding-left: 10px; */
  }
}

.about-us-section .choose-btm .choose-left-content .sl {
  padding-top: 65px;
  padding-left: 60px;
}

@media (max-width: 1199px) {
  .about-us-section .choose-btm .choose-left-content .sl {
    padding-left: 30px;
  }
}

@media (max-width: 576px) {
  .about-us-section .choose-btm .choose-left-content .sl {
    padding-left: 10px;
  }
}

.about-us-section .choose-btm .choose-left-content .sl h2 {
  font-family: var(--font-saira);
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: rgba(228, 228, 228, 0.3);
}

.about-us-section .choose-btm .choose-left-content .about-btn {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.about-us-section .choose-btm .choose-left-content .about-btn a {
  width: 123px;
  height: 123px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-saira);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--white-color);
  gap: 5px;
  transition: 0.7s;
}

.about-us-section .choose-btm .choose-left-content .about-btn a svg {
  stroke: var(--white-color);
  transition: 0.7s;
}

.about-us-section .choose-btm .choose-left-content .about-btn a:hover {
  border-color: var(--theme-color);
  box-shadow: inset 0 0 0 10em var(--theme-color);
}

.about-us-section .choose-btm .choose-left-content .about-btn a:hover svg {
  transform: rotate(45deg);
}

.about-us-section .choose-btm .choose-left-content .choose-vec-top-r {
  position: absolute;
  bottom: 0;
  left: 0;
}

.about-us-section .choose-btm .choose-left-content .choose-vec-btm-l {
  position: absolute;
  right: 0;
  top: 0;
}

/* .about-us-section .choose-btm .choose-feature ul li {
  margin-bottom: 40px;
} */
.about-us-section .choose-btm .choose-feature ul li:last-child {
  margin-bottom: 0;
}

/* .about-us-section .choose-btm .choose-feature ul li .single-feature {
  display: flex;
  align-items: center;
  gap: 25px;
} */

.about-us-section .choose-btm .choose-feature ul li .single-feature .progress {
  height: 100px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #1971D1;
}

.about-us-section .choose-btm .choose-feature ul li .single-feature .progress h3 {
  margin-bottom: 0;
  font-family: var(--font-saira);
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  color: var(--white-color);
}

.about-us-section .choose-btm .choose-feature ul li .single-feature .content {
  /* max-width: 290px; */
  width: 100%;
}

.about-us-section .choose-btm .choose-feature ul li .single-feature i {
  font-size: 40px;
  color: #1654AF;
}

.about-us-section .choose-btm .choose-feature ul li .single-feature .content h4 {
  font-family: var(--font-saira);
  font-weight: 600;
  font-size: 25px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 8px;
}

.about-us-section .choose-btm .choose-feature ul li .single-feature .content p {
  font-family: var(--font-saira);
  font-weight: 300;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.03em;
  /* color: #B5B5B5; */
}

#particles {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#intro {
  position: absolute;
  left: 0;
  top: 50%;
  padding: 0 20px;
  width: 100%;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: relative;
}

.preloader .content {
  width: 100vw;
  height: 100vh;
}

.preloader .test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader .test img {
  width: 100px;
}

.loader-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: -60px;
  margin-top: -60px;
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  animation: rotate 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #1E86ED
}

.home3-success-stories-area {
  background: #1B1F23;
  padding: 100px 8%;
}

.home3-success-stories-area.two {
  background-color: transparent;
  padding: 0 8%;
  padding-top: 50px;
  padding-bottom: 120px;
}

@media (max-width: 1700px) {
  .home3-success-stories-area.two {
    padding: 0px 3%;
    padding-top: 50px;
    padding-bottom: 120px;
  }
}

@media (max-width: 1199px) {
  .home3-success-stories-area.two {
    padding: 0px 1%;
    padding-top: 50px;
    padding-bottom: 120px;
  }
}

@media (max-width: 1700px) {
  .home3-success-stories-area {
    padding: 100px 3%;
  }
}

@media (max-width: 1199px) {
  .home3-success-stories-area {
    padding: 100px 0%;
  }
}

.home3-success-stories-area .swiper-slide:nth-child(even) {
  margin-top: 30px;
}

@media (max-width: 1199px) {
  .home3-success-stories-area .swiper-slide:nth-child(even) {
    margin-top: 0;
  }
}

.home3-success-stories-area .success-storie-card {
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
}

.home3-success-stories-area .success-storie-card h3 {
  color: #fff;
}

.home3-success-stories-area .success-storie-card .success-img {
  border-radius: 5px;
  box-shadow: 3px 0 60px rgba(103, 103, 103, 0.6);
}

.home3-success-stories-area .success-storie-card .success-img img {
  border-radius: 5px;
  width: 100%;
}

.home3-success-stories-area .success-storie-card .success-content {
  position: absolute;
  z-index: 1;
  left: 25px;
  right: 25px;
  bottom: 25px;
  background-color: #1971D1;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  padding: 35px 25px;
  border-radius: 5px;
  transition: 0.5s;
}

.home3-success-stories-area .success-storie-card .success-content a {
  text-decoration: none;
}

@media (max-width: 1399px) {
  .home3-success-stories-area .success-storie-card .success-content {
    padding: 35px 20px;
  }
}

@media (max-width: 1399px) {
  .home3-success-stories-area .success-storie-card .success-content {
    padding: 25px 20px;
  }
}

.home3-success-stories-area .success-storie-card .success-content span {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #B5B5B5;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}

.home3-success-stories-area .success-storie-card .success-content span::after {
  content: "";
  height: 1px;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  left: 0;
  bottom: -3px;
}

.home3-success-stories-area .success-storie-card .success-content h3 {
  margin-bottom: 0;
}

.home3-success-stories-area .success-storie-card .success-content h3 a {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 26px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
  transition: 0.5s;
}

@media (max-width: 1399px) {
  .home3-success-stories-area .success-storie-card .success-content h3 a {
    font-size: 24px;
  }
}

.home3-success-stories-area .success-storie-card .success-content h3 a:hover {
  color: var(--theme-color);
}

.home3-success-stories-area .success-storie-card .success-content .view-btn {
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a svg {
  stroke: #1B1F23;
  transform: rotate(90deg);
  transition: 0.5s;
}

.home3-success-stories-area .success-storie-card:hover .success-content {
  bottom: 50px;
}

.home3-success-stories-area .success-storie-card:hover .success-content .view-btn a svg {
  transform: rotate(45deg);
}

.home3-success-stories-area .swiper-pagination11 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: unset !important;
  bottom: unset !important;
  display: flex;
  gap: 15px;
}

@media (max-width: 767px) {
  .home3-success-stories-area .swiper-pagination11 {
    display: none;
    visibility: hidden;
  }
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  opacity: 1;
  position: relative;
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet::after {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: var(--theme-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active {
  border-color: var(--theme-color);
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active::after {
  opacity: 1;
}

.portfolio-details {
  padding-top: 50px;
  padding-bottom: 120px;
}

.portfolio-details .portfolio-img {
  border-radius: 10px;
  height: 100%;
}

.portfolio-details .portfolio-img img {
  border-radius: 10px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.portfolio-details .portfolio-content h3 {
  font-family: var(--font-saira);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .portfolio-details .portfolio-content h3 {
    font-size: 30px;
  }
}

.portfolio-details .portfolio-content p {
  font-family: var(--font-saira);
  font-weight: 300;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #B5B5B5;
  margin-bottom: 15px;
}

.portfolio-details .portfolio-content p:last-child {
  margin-bottom: 0;
}

.portfolio-details .portfolio-content .working-process {
  padding-top: 20px;
  margin-bottom: 70px;
}

.portfolio-details .portfolio-content .working-process h3 {
  margin-bottom: 25px;
}

.portfolio-details .portfolio-content .working-process .single-process {
  border: 2px solid #1971D1;
  border-radius: 5px;
  padding: 35px 20px;
  position: relative;
}

.portfolio-details .portfolio-content .working-process .single-process .icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.portfolio-details .portfolio-content .working-process .single-process span {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: var(--theme-color);
  display: inline-block;
  margin-bottom: 10px;
}

.portfolio-details .portfolio-content .working-process .single-process h3 {
  font-family: var(--font-saira);
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  color: var(--white-color);
}

@media (max-width: 767px) {
  .portfolio-details .portfolio-content .working-process .single-process h3 {
    font-size: 24px;
  }
}

.portfolio-details .portfolio-content .working-process .single-process p {
  font-family: var(--font-saira);
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #B5B5B5;
}

.portfolio-details .portfolio-info {
  /* background-image: url(../img/inner-pages/portfolio-info-bg.png); */
  background-color: #1971D1;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-bottom: 50px;
  color: #fff;
}

.portfolio-details .portfolio-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 15px 0;
}

.portfolio-details .portfolio-info ul li {
  text-align: center;
  padding: 25px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.portfolio-details .portfolio-info ul li:last-child {
  border-bottom: unset;
}

.portfolio-details .portfolio-info ul li span {
  font-family: var(--font-saira);
  font-weight: 400;
  font-size: 15px;
  color: #B5B5B5;
  display: inline-block;
  margin-bottom: 5px;
}

.portfolio-details .portfolio-info ul li h5 {
  margin-bottom: 0;
  font-family: var(--font-saira);
  font-weight: 600;
  font-size: 20px;
  color: var(--white-color);
}

.portfolio-details .portfolio-details-sm-banner {
  /* background-image: url(../img/inner-pages/portfolio-dt-06.png); */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 30px 240px;
  border-radius: 20px;
}

.portfolio-details .portfolio-details-sm-banner .section-title-5 h2 {
  font-size: 40px;
  margin-bottom: 30px;
}

.portfolio-details .portfolio-details-sm-banner .section-title-5 a {
  padding: 14px 40px;
}

.portfolio-details .magnetic-wrap {
  height: 100%;
}

.why-choose-us-area {
  /* background-image: url('./contact-form-bg.jpg'); */
  background-size: cover;
}

.project-area-three .section-title {
  color: var(--heading-color);
}

.project-area-three .subtitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  color: #fff;
  transition: 0.5;
  z-index: 2;
}

.project-area-three .project-grid-item:hover .project-area-three .subtitle {
  opacity: 1;
}

.price-plan-area .pricing-plan-item .bi-check-circle-fill {
  color: #5cb85c;
}

.price-plan-area .pricing-plan-item .bi-x-circle-fill {
  color: #d9534f;
}

.price-plan-area .pricing-plan-item.active {
  background-color: #1654AF;
  color: #fff;
}

.price-plan-area .pricing-plan-item.active .price {
  color: #fff;
}

.service-tab:hover .service-tab ul {
  display: block !important;
}


.contact-page-wrap .contact-content h2 {
  margin-bottom: 5px;
  /* font-family: var(--font-saira); */
  font-weight: 700;
  /* font-size: 42px; */
  line-height: 1.4;
  letter-spacing: 0.03em;
  /* text-transform: capitalize; */
  /* color: var(--white-color); */
}

@media (max-width: 767px) {
  .contact-page-wrap .contact-content h2 {
    font-size: 38px !important;
  }
}

@media (max-width: 576px) {
  .contact-page-wrap .contact-content h2 {
    font-size: 30px !important;
  }
}

.contact-page-wrap .contact-content p {
  font-family: var(--font-saira);
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.03em;
  /* color: #B5B5B5; */
  margin-bottom: 0;
}

.contact-page-wrap .contact-content .informations {
  padding-top: 50px;
}

.contact-page-wrap .contact-content .informations .single-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

.contact-page-wrap .contact-content .informations .single-info .icon {
  height: 2.8rem;
  min-width: 2.8rem;
  border-radius: 50%;
  border: 1px solid #1654AF;
  color: #1654AF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-page-wrap .contact-content .informations .single-info .icon i {
  font-size: 20px;
}

.contact-page-wrap .contact-content .informations .single-info .info a {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  display: block;
  transition: all 0.5s ease;
  text-decoration: none;
}

.contact-page-wrap .contact-content .informations .single-info .info a:hover {
  color: var(--theme-color);
}

.contact-page-wrap .contact-content .informations .single-info .info p {
  font-size: 16px;
  font-weight: 400;
  /* color: #B5B5B5; */
  display: block;
  transition: all 0.5s ease;
}

.contact-page-wrap .contact-content .follow-area {
  padding: 30px 0px;
}

.contact-page-wrap .contact-content .follow-area h5 {
  font-size: 22px;
  font-weight: 700;
  color: var(--white-color);
  position: relative;
  margin-bottom: 0px;
}

.contact-page-wrap .contact-content .follow-area .para {
  font-size: 16px;
  font-weight: 400;
  color: #B5B5B5;
  font-family: poppins, sans-serif;
  line-height: 28px;
  margin-bottom: 10px;
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list {
  margin: 0;
  padding: 10px 0 0;
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list li a {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  background: transparent;
  border-radius: 3px;
  font-size: 18px;
  color: var(--white-color);
  transition: all 0.35s ease-in-out;
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list li a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.contact-page-wrap .contact-form-wrap {
  /* background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
}

@media (min-width: 992px) {
  .contact-page-wrap .contact-form-wrap {
    margin-left: 25px;
  }
}

.contact-page-wrap .contact-form-wrap .form-tltle {
  padding: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-page-wrap .contact-form-wrap .form-tltle h5 {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 22px;
  color: var(--white-color);
  margin-bottom: 0;
  text-align: center;
}

.contact-page-wrap .contact-form-wrap .contact-form {
  padding: 40px 45px;
}

@media (max-width: 1399px) {
  .contact-page-wrap .contact-form-wrap .contact-form {
    padding: 40px 25px;
  }
}

.contact-page-wrap .contact-form-wrap .contact-form form label {
  font-family: var(--font-saira);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 8px;
}

.contact-page-wrap .contact-form-wrap .contact-form form input,
.contact-page-wrap .contact-form-wrap .contact-form form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  height: 41px;
  font-family: var(--font-saira);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #dedada;
  padding: 10px 15px;
}

.contact-page-wrap .contact-form-wrap .contact-form form textarea {
  height: 140px;
}

.contact-page-wrap .contact-form-wrap .contact-form form .primary-btn3 {
  font-size: 17px;
  font-weight: 700;
  padding: 12px 62px;
  border: none;
  margin-top: 20px;
}

.our-clients img {
  max-height: 100px;
  width: auto;
}

.about-area-five {
  background: #F7F7F9;
}

.services-content-three .list-style-one i {
  font-size: 25px;
}

@media (min-width: 748px) {
  .services-content-three {
    margin-right: 20px;
  }
}

.services-content-three .list-style-one li {
  font-size: 20px;
  font-weight: 600;
}

.add-container img {
  width: 200px;
}


.about-seven {
  /* background: #1B2D58;
  color: #fff; */

}

.about-seven-image img {
  border-radius: 10px;
}

.about-seven-content .list-style-three li {
  /* color: black; */
  font-weight: 700;
  margin-bottom: 20px;
}

.about-seven-content .list-style-three li i {
  font-size: 24px;
  color: #1971D1;
}

.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #1B2D58 !important;
  width: 16rem;
  height: fit-content;
  padding: 10px;
  border-radius: 10px;
  width: 250px;
  color: #fff !important;
  text-align: center;
}

.cardTop {
  width: 335px;
  height: 120px;
  margin-top: -15%;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 30px;
}

@media (min-width: 748px) {
  .card {
    width: 400px;
    padding: 20px;
  }

  .cardTop {
    height: 250px;
  }
}

.card img {
  height: 140px;
  width: auto;
}

.card h3 {
  margin-top: 10px;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card .card-link {
  font-size: 14px;
}

.service-details-area {
  background-color: #1B2D58;
}

.service-slider {
  transform: translateY(-15%);
  margin-top: 150px;
  margin-left: 20px;
  margin-right: 20px
}

@media(min-width: 748px) and (max-width: 991px) {
  .service-slider {
    transform: translateY(-20%);
    /* margin-top: 150px; */
  }
}

@media(min-width: 991px) {
  .service-slider {
    transform: translateY(-30%);
    /* margin-top: 150px; */
  }
}

.service-slider .project-slider-item {
  border-radius: 10px;
}

@media (min-width: 993px) and (max-width: 1199px) {
  .service-slider .project-slider-item img {
    max-width: 300px
  }
}

.technology-slider-mobile img.icon {
  /* height: 40px; */
}

.technology-slider-mobile .swiper {
  border-bottom: 1px solid #E7E7E8;
  padding-bottom: 10px;
}

.service-chevron {
  color: #1654AF;
  font-weight: 600;
  cursor: pointer;
}

.home-card-text {
  color: var(--heading-color);
}

/* .outsourcing-section {
  background: #1B2D58;
  color: #fff;
} */

.outsourcing-section .about-left-img-wrap {
  max-width: 600px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.outsourcing-section .about-left-img-wrap .big-img {
  border-radius: 190px;
  position: relative;
}

/* .outsourcing-section .about-left-img-wrap .big-img::after {
  content: "";
  background: linear-gradient(180deg, rgba(34, 38, 42, 0.6) 0%, rgba(34, 38, 42, 0.1) 100%);
  border-radius: 190px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
} */
.outsourcing-section .about-left-img-wrap .big-img img {
  border-radius: 10px;
}

.outsourcing-section .about-left-img-wrap .sm-img {
  width: 148px;
  height: 148px;
  border-radius: 50%;
  border: 1px solid var(--theme-color);
  position: relative;
  position: absolute;
  bottom: 0;
  right: -45px;
}

@media (max-width: 576px) {
  .outsourcing-section .about-left-img-wrap .sm-img {
    right: 0;
  }
}

.outsourcing-section .about-left-img-wrap .sm-img::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--theme-color);
  opacity: 0.2;
  border-radius: 50%;
}

.outsourcing-section .about-left-img-wrap .sm-img img {
  width: 148px;
  height: 148px;
  border-radius: 50%;
}

.outsourcing-section .about-bottom {
  padding-top: 100px;
}

.outsourcing-section .about-bottom .text {
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  .outsourcing-section .about-bottom {
    padding-top: 80px;
  }
}

.outsourcing-section .about-left-content .about-feature {
  /* margin-top: 25px; */
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding-top: 40px;
}

.outsourcing-section .about-left-content .about-feature ul {
  margin: 0;
  padding: 0;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 576px) {
  .outsourcing-section .about-left-content .about-feature ul {
    -moz-columns: 1;
    columns: 1;
  }
}

.outsourcing-section .about-left-content .about-feature ul li {
  font-family: var(--font-saira);
  font-weight: 400;
  font-size: 17px;
  /* letter-spacing: 0.02em; */
  color: var(--white-color);
  display: flex;
  align-items: center;
  /* gap: 15px; */
  margin-bottom: 25px;
}

@media (max-width: 748px) {
  .outsourcing-section .about-left-content .about-feature ul li {
    margin-bottom: 0px;
  }
}

.outsourcing-section .about-left-content .about-feature ul li i {
  font-size: 24px;
  color: #1971D1;
}

.outsourcing-section .about-left-content .about-feature ul li:nth-child(even) {
  margin-bottom: 0;
}

@media (max-width: 576px) {
  .outsourcing-section .about-left-content .about-feature ul li:nth-child(even) {
    margin-bottom: 0px;
  }

  .outsourcing-section .about-left-content .about-feature ul li:last-child {
    margin-bottom: 0;
  }
}

.flip-card {
  background-color: transparent;
  width: 276px;
  height: 336px;
  perspective: 1000px;
}

@media(min-width: 1400px) {
  .flip-card {
    background-color: transparent;
    width: 318px;
    height: 390px;
    perspective: 1000px;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-back .content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

/* .flip-card-front {
  background-color: #bbb;
  color: black;
} */

.flip-card-back {
  background-color: #1B2D58;
  color: white;
  transform: rotateY(180deg);
  z-index: 3;
}

.testimonials-three-area {
  background: #F6F6F9;
}

.erp-section-two {
  background-color: var(--primary-color);
  color: #fff;
}

.img-fluid {
  border-radius: 10px;
}

.outsourcing-section .contact-shapes .shape.one {
  top: 300px
}

.outsourcing-section .contact-shapes .shape.two {
  top: 0
}

.partners-area-two {
  background-color: #1B2D58;
}

@-webkit-keyframes fadeInLeft-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft-animation {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRight-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight-animation {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.techFadeInLeft {
  -webkit-animation-name: fadeInLeft-animation;
  animation-name: fadeInLeft-animation;
  animation-duration: 1s;
}

.techFadeInRight {
  -webkit-animation-name: fadeInRight-animation;
  animation-name: fadeInRight-animation;
  animation-duration: 1s;
}

.job-description h5 {
  margin-left: 10px;
}

.job-description ul {
  list-style: none
}

.job-description ul li::before {
  content: "•";
  color: #000;
  margin-right: 5px;
}

.rfm-marquee-container {
  overflow: hidden;
}

.react-multi-carousel-track li {
  display: flex;
  justify-content: center;
}